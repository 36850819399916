import { useContext } from "react";
import "./App.css";
import Login from "./components/login";
import { AuthContext } from "./context/auth";
import Home from "./components/home";
import { Switch, Route } from "react-router-dom";
import News from "./components/news";
import SingleNews from "./components/news/singleNews";
import Applications from "./components/applications";
import Ipls from "./components/ipl";
import Settings from "./components/settings";
import Application from "./components/applications/application";
import Ipl from "./components/ipl/ipl";
import ErrorScreen from "./components/error";


function App() {
  const { state } = useContext(AuthContext);
  
  //console.log({ state });
  if (!state.token) {
    return <Login />;
  }
  return (
    <div>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/applications">
          <Applications />
        </Route>
        <Route exact path="/applications/:id">
          <Application />
        </Route>
        <Route exact path="/ipl">
          <Ipls />
        </Route>
        <Route exact path="/ipl/:id">
          <Ipl />
        </Route>
        <Route exact path="/settings">
          <Settings />
        </Route>
        <Route exact path="/news">
          <News />
        </Route>
        <Route exact path="/news/:id">
          <SingleNews />
        </Route>
        <Route exact path="/error">
        <ErrorScreen />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
