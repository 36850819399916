import React, { useContext, useState } from "react";
import Layout from "../layout";
import { AiOutlineMail, AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { AuthContext } from "../../context/auth";
import { LOGIN } from "../../context/types";
import api from "../../service/axios";
import Loading from "../loading";

function Login() {
  // test@indigoconcept.uk
  // U5mK kDoV enyY 9RZc R7VG DWJN"
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isShowPass, setIsShowPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const [error,setError]=useState()
  const handleLogin = async () => {
    setLoading(true);
    try {
      const data = await api.get(`/user?email=${email}&password=${password}`);

      const basicAuthCredentials = window.btoa(email + ":" + password);
      if (data.status === 200) {
        localStorage.setItem("token", basicAuthCredentials);
        localStorage.setItem("display_name", data.data.display_name);
        localStorage.setItem("email", data.data.email);
        localStorage.setItem("first_name", data.data.first_name);
        localStorage.setItem("id", data.data.id);
        localStorage.setItem("last_name", data.data.last_name);
        localStorage.setItem("nickname", data.data.nickname);
        localStorage.setItem(
          "last_login_friendly",
          data.data.last_login_friendly
        );
        setTimeout(() => {
          dispatch({
            type: LOGIN,
            payload: {
              token: localStorage.getItem("token"),
              display_name: localStorage.getItem("display_name"),
              email: localStorage.getItem("email"),
              first_name: localStorage.getItem("first_name"),
              id: localStorage.getItem("id"),
              last_name: localStorage.getItem("last_name"),
              nickname: localStorage.getItem("nickname"),
              last_login_friendly: localStorage.getItem("last_login_friendly"),
            },
          });
        }, 2000);

        setLoading(false);
      }
    } catch (error) {
      setError(error)
      console.log({error})
      setLoading(false);
    }
  };
  return (
    <Layout>
      <div className="content">

        {loading && <Loading />}
        <div className="md:w-4/12 m-auto mt-20">
          <h1 className="text-4xl font-bold text-primary_text">
            CMMT Trustees
          </h1>
          <p
            style={{ fontSize: "1.3rem" }}
            className="text-primary_text text-justify"
          >
            To continue please login with the username and password you have
            been provided with
          </p>
          <div className="relative">
            <input
              className="w-full bg-input_color p-3 pl-10 mt-5"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
            <div className="absolute left-3 top-8 text-button_color">
              <AiOutlineMail size={24} />
            </div>
          </div>

          <div className="relative ">
            <input
              className="w-full bg-input_color p-3 pl-10 mt-5"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={isShowPass ? "text" : "password"}
              placeholder="Password"
            />
            <div className="absolute left-3 top-8 text-button_color">
              <RiLockPasswordLine size={24} />
            </div>
            <div
              className="absolute right-3 top-8 text-button_color"
              onClick={() => setIsShowPass(!isShowPass)}
            >
              {isShowPass ? (
                <AiFillEye size={24} />
              ) : (
                <AiFillEyeInvisible size={24} />
              )}
            </div>
          </div>
          <label>
            <input type="checkbox" className="bg-input_color p-3 mt-4" />{" "}
            Remember me
          </label>
          <button
            onClick={async () => {
              await handleLogin();
            }}
            className="button block w-full text-input_color p-3 mt-4"
          >
            Log in
          </button>
        </div>

      </div>
      <div className="text-center text-red-600 text-1 ">
          {error && error?.response?.data?.message }
        </div>
    </Layout>
  );
}

export default Login;
