import React from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Footer from "../footer";
function ErrorScreen() {
  const location = useLocation();
  const history = useHistory();
  console.log(location.pathname);
  return (
    <div>
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <div className="content text-center">
        <h1 className="mb-10">
          Error
        </h1>
        <p>Cannot connect to the server</p>
        <div className="mt-10 text-center">
          <button onClick={() => history.goBack()}>Go Back</button>
        </div>
      </div>
    </div>
    <div
    className="fixed bottom-0"
    >
      <Footer />
    </div>
    </div>
  );
}

export default ErrorScreen;
