import React, { useState } from "react";
import { FaHome, FaGraduationCap, FaGuitar, FaBullhorn } from "react-icons/fa";
import { MdSettings } from "react-icons/md";
import useWindowDimensions from "../../hooks/useWindowDimension";
import { Link, useLocation } from "react-router-dom";
function Footer() {
  const [active, setActive] = useState(1);
  const { width } = useWindowDimensions();
  const location = useLocation();
  //console.log('location: ' + location.pathname);
  return (
    <div id="footer" className="w-screen bg-light-grey">
      <div className="m-auto flex justify-center">
        <div
          className={
            location.pathname === "/applications"
              ? `menu-item active`
              : `menu-item`
          }
          onClick={() => setActive(2)}
        >
          <Link to="/applications">
            <FaGraduationCap size={width < 500 ? 28 : 40} />
            <p>Grant</p>
          </Link>
        </div>
        <div
          className={
            location.pathname === "/ipl"
              ? `menu-item active`
              : `menu-item`
          }
          onClick={() => setActive(3)}
        >
          <Link to="/ipl">
            <FaGuitar size={width < 500 ? 28 : 40} />
            <p>IPL</p>
          </Link>
        </div>
        <div
          className={
            location.pathname === "/"
              ? `home menu-item active`
              : `home menu-item `
          }
          onClick={() => setActive(1)}
        >
          <Link to="/">
            <FaHome size={width < 500 ? 28 : 40} />
          </Link>
        </div>
        <div
          className={
            location.pathname === "/news"
              ? `menu-item active`
              : `menu-item`
          }
          onClick={() => setActive(4)}
        >
          <Link to="/news">
            <FaBullhorn size={width < 500 ? 28 : 40} />
            <p>News</p>
          </Link>
        </div>
        <div
          className={
            location.pathname === "/settings"
              ? `menu-item active`
              : `menu-item`
          }
          onClick={() => setActive(5)}
        >
          <Link to="/settings">
          <MdSettings size={width < 500 ? 28 : 40} className="ml-2" />
          <p>Settings</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
