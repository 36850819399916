import React, { useContext } from "react";
import Layout from "../layout";
import Version from "../../version";
import { AuthContext } from "../../context/auth";
import { LOGOUT } from "../../context/types";

function Settings() {
  const { dispatch } = useContext(AuthContext);

  const logout = () => {
    console.log("LOGOUT");
    dispatch({ type: LOGOUT });
  };

  const clearCache = () => {
    // User
    localStorage.removeItem("last_name");
    localStorage.removeItem("display_name");
    localStorage.removeItem("last_login");
    localStorage.removeItem("last_login_friendly");
    // Home
    localStorage.removeItem("total_studygrants");
    localStorage.removeItem("total_ipl");
    localStorage.removeItem("recent_news");
    // Study Grant
    localStorage.removeItem("applications");
    localStorage.removeItem("totalApplication");
    // IPL

    // News
    localStorage.removeItem("news");
    localStorage.removeItem("totalNews");
    // Indvidual Items
    /*for (key in localStorage) { // Remove individual items
      console.log(key);
      if (localStorage[key].substring(0,9) == 'studygrant-') {
        localStorage.removeItem(key);
      }
    }*/
    var i,key,toDelete;
    for (i = 0; i < localStorage.length; i++) {
      key = localStorage.key(i);
      if (localStorage.key(i).substring(0,8) === 'hideTip-') {
        //console.log('key: ' + key);
        localStorage.removeItem(key);
      }
      if (localStorage.key(i).substring(0,11) === 'studygrant-') {
        //console.log('key: ' + key);
        localStorage.removeItem(key);
      }
      if (localStorage.key(i).substring(0,4) == 'ipl-') {
        localStorage.removeItem(key);
        //toDelete.push(JSON.parse(window.localStorage.getItem(key)));
        //localStorage.removeItem(key);
      }
      if (localStorage.key(i).substring(0,5) == 'news-') {
        localStorage.removeItem(key);
        //toDelete.push(JSON.parse(window.localStorage.getItem(key)));
      }
    }
    //console.log(toDelete);
    /*for (let i = 0; i < localStorage.length; i++) {
       let key = localStorage.key(i);
       if (localStorage[key].substring(0,9) == 'studygrant-') {
       }
       if (localStorage[key].substring(0,9) == 'ipl-') {
         localStorage.removeItem(key);
       }
       if (key.substring(0,9) == 'news-') {
         localStorage[key].removeItem(key);
       }
       console.log(localStorage.getItem(key));
    }*/
  };
  const reloadApp = () => {
    window.close();
  };

  return (
    <Layout>
      <div id="content" className="loaded">
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-12">
          <h1 className="">Settings </h1>
          <p>The following are the settings available for this app</p>
        </div>
        <div className="col-span-12 md:col-span-6">
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2 mt-10">
        <div className="col-span-12">
          <h4 className="">Reset User / Logout </h4>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <p>
            Use this button to logout of the app & reset the associated user account.  Use if having access issues.
          </p>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <button
            onClick={logout}
            className="button"
          >
            Reset User
          </button>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2 mt-10">
        <div className="col-span-12">
          <h4 className="">Clear Cache</h4>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <p>
            Use this button to clear the cached applications data to enable fresh data to be downloaded
          </p>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <button
            onClick={clearCache}
            className="button"
          >
            Clear Cache
          </button>
        </div>
      </div>
      <div className="mt-10 version-wrapper">
        <div>Version:
        <Version />
        </div>
      </div>
      </div>
    </Layout>
  );
}

export default Settings;
