import axios from "axios";

const api = axios.create({
  //baseURL: "https://munster.indigoconcept.dev/wp-json/api/v1",
  baseURL: "https://www.munstertrust.org.uk/wp-json/api/v1",
  timeout: 30000,
});

api.interceptors.request.use(
  async (config) => {
    // const basicAuthCredentials = window.btoa("test@indigoconcept.uk" + ":" + "U5mK kDoV enyY 9RZc R7VG DWJN");
    config.headers.common["Authorization"] = `Basic ${localStorage.getItem(
      "token"
    )}`;
    return config;
  },
  (error) => {
     return error;
  }
);

export default api;
