import React, { useEffect } from "react";
import YouTube from "react-youtube";
function Youtube({ videoURL }) {

  const [videoUrl, setVideoUrl] = React.useState();
  let videoCode;
  if (videoUrl) {
    videoCode = videoUrl.split("v=")[1].split("&")[0];
  }

  useEffect(() => {
    setVideoUrl(videoURL);
  }, [videoURL]);

  const checkElapsedTime = (e) => {
    // const duration = e.target.getDuration();
    // const currentTime = e.target.getCurrentTime();

  };

  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  // const handleExerciseComplete = () => console.log("Do something");

  return (
    <div className="w-full mb-5 ">
      <div>
        <YouTube
        className="w-full"
          videoId={videoCode}
          containerClassName="embed embed-youtube"
          onStateChange={(e) => checkElapsedTime(e)}
          opts={opts}
        />
      </div>
    </div>
  );
}

export default Youtube;
