import React from "react";
import Youtube from "./youtube";
const nl2br = require('react-nl2br');
export class ComponentToPrint extends React.PureComponent {
  render() {
    //console.log("props", this.props);
    return (
      <div className="print-wrapper">
        <div className="page-sub-header mt-5">Grant Application</div>
        <h1 className="text-2xl  md:text-4xl font-bold ">
          <span className="uppercase">{this.props.data?.last_name}</span> {this.props.data?.first_name}
        </h1>
        <div id="summary-info" className="tab-content first">
          <div className="grid grid-cols-12 gap-2 mt-5">
            <div className="col-span-6 md:col-span-2">
              <div className="field-wrapper">
                <div className="label">A Number</div>
                <div className="value">{this.props.data?.a_number === null ? "" : this.props.data?.a_number}</div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <div className="field-wrapper">
                <div className="label">Instrument</div>
                <div className="value">{this.props.data?.instrument_friendly === null ? "" : this.props.data?.instrument_friendly}</div>
              </div>
            </div>
            <div className="col-span-6 md:col-span-2">
              <div className="field-wrapper">
                <div className="label">Age</div>
                <div className="value">{this.props.data?.age === null ? "" : this.props.data?.age}</div>
              </div>
            </div>
            <div className="col-span-6 md:col-span-2">
              <div className="field-wrapper">
                <div className="label">Year</div>
                <div className="value">{this.props.data?.year === null ? "" : this.props.data?.year}</div>
              </div>
            </div>
            <div className="col-span-6 md:col-span-2">
              <div className="field-wrapper">
                <div className="label">Required</div>
                <div className="value">
                  {this.props.data?.required_1 === null ? "" : new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "GBP",
                    maximumFractionDigits: 0
                  }).format(this.props.data?.required_1)}
                </div>
              </div>
            </div>
            <div className="col-span-6 md:col-span-2">
              <div className="field-wrapper">
                <div className="label">&nbsp;</div>
                <div className="value">
                  {this.props.data?.required_2 === null ? "" : new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "GBP",
                    maximumFractionDigits: 0
                  }).format(this.props.data?.required_2)}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="personal-info" className="tab-content mt-10">
        <h1 className="text-4xl mb-5">Personal </h1>
        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-12 md:col-span-4">
            <div className="field-wrapper">
              <div className="label">Nationality</div>
              <div className="value">
                {this.props.data?.nationality === null ? "" : this.props.data?.nationality}
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-2">
            <div className="field-wrapper">
              <div className="label">Date of birth</div>
              <div className="value">
                {this.props.data?.dob === null ? "" : this.props.data?.dob}
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="field-wrapper">
              <div className="label">Previous Surname</div>
              <div className="value">
                {this.props.data?.previous_surname === null ? "" : this.props.data?.previous_surname}
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <div className="field-wrapper">
              <div className="label">Phone</div>
              <div className="value">
                {this.props.data?.phone === null ? "" : this.props.data?.phone}
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-8">
            <div className="field-wrapper">
              <div className="label">Email</div>
              <div className="value">
                {this.props.data?.email === null ? "" : this.props.data?.email}
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="field-wrapper">
              <div className="label">Permanent Address</div>
              <div className="value">
                {this.props.data?.address_permanent === null ? "" : nl2br(this.props.data?.address_permanent)}
                <br/>
                {this.props.data?.address_permanent_city === null ? "" : this.props.data?.address_permanent_city}
                <br/>
                {this.props.data?.address_permanent_postcode === null ? "" : this.props.data?.address_permanent_postcode}
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="field-wrapper">
              <div className="label">Term Time Address</div>
              <div className="value">
                {this.props.data?.address_term === null ? "" : nl2br(this.props.data?.address_term)}
                <br/>
                {this.props.data?.address_term_city === null ? "" : this.props.data?.address_term_city}
                <br/>
                {this.props.data?.address_term_postcode === null ? "" : this.props.data?.address_term_postcode}
              </div>
            </div>
          </div>
        </div>
        </div>

        <div id="course-info" className="tab-content mt-10">
          <h1 className="text-4xl mb-5">Course</h1>
          <div id="inst-1" className="field-group">
            <div className="field-group-header" style={{ marginTop: "0" }}>Institution 1</div>
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 md:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Name of intended institution</div>
                  <div className="value">
                    {this.props.data?.inst_1_name_visible === null ? "" : this.props.data?.inst_1_name_visible}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Type of course</div>
                  <div className="value">
                    {this.props.data?.inst_1_course_type === null ? "" : this.props.data?.inst_1_course_type}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Title of course</div>
                  <div className="value">
                    {this.props.data?.inst_1_course_name === null ? "" : this.props.data?.inst_1_course_name}
                  </div>
                </div>
              </div>
              <div className="col-span-6 md:col-span-3">
                <div className="field-wrapper">
                  <div className="label">Length of course</div>
                  <div className="value">
                    {this.props.data?.inst_1_course_length === null ? "" : this.props.data?.inst_1_course_length}
                  </div>
                </div>
              </div>
              <div className="col-span-6 md:col-span-3">
                <div className="field-wrapper">
                  <div className="label">Related Year</div>
                  <div className="value">
                    {this.props.data?.inst_1_course_year === null ? "" : this.props.data?.inst_1_course_year}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-9">
                <div className="field-wrapper">
                  <div className="label">Name(s) of teacher(s)</div>
                  <div className="value">
                    {this.props.data?.inst_1_course_teachers === null ? "" : this.props.data?.inst_1_course_teachers}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-3">
                <div className="field-wrapper">
                  <div className="label">Application Status</div>
                  <div className="value">
                    {this.props.data?.inst_1_course_status === null ? "" : this.props.data?.inst_1_course_status}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="field-group-sub-header">Outgoings</div>
                <div className="grid grid-cols-12 gap-2">
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Tuition Fees</div>
                      <div className="value">
                        {this.props.data?.inst_1_out_fees === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_1_out_fees)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Rent</div>
                      <div className="value">
                        {this.props.data?.inst_1_out_rent === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_1_out_rent)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Maintenance</div>
                      <div className="value">
                        {this.props.data?.inst_1_out_maintenance === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_1_out_maintenance)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Additional Travel Costs</div>
                      <div className="value">
                        {this.props.data?.inst_1_out_travel === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_1_out_travel)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-wrapper is-total">
                      <div className="label">TOTAL</div>
                      <div className="value">
                        {this.props.data?.inst_1_out_total === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_1_out_total)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-wrapper">
                      <div className="label">Other Living Costs</div>
                      <div className="value textarea">
                        {this.props.data?.inst_1_out_info === null ? "" : this.props.data?.inst_1_out_info}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="field-group-sub-header">Incoming</div>
                <div className="grid grid-cols-12 gap-2">
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Scholarship / Bursary</div>
                      <div className="value">
                        {this.props.data?.inst_1_in_bursary === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_1_in_bursary)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Family Support</div>
                      <div className="value">
                        {this.props.data?.inst_1_in_family === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_1_in_family)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Personal Savings</div>
                      <div className="value">
                        {this.props.data?.inst_1_in_savings === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_1_in_savings)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Teaching</div>
                      <div className="value">
                        {this.props.data?.inst_1_in_teaching === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_1_in_teaching)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Earnings (non-musical)</div>
                      <div className="value">
                        {this.props.data?.inst_1_in_nonmusical === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_1_in_nonmusical)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Earnings (musical)</div>
                      <div className="value">
                        {this.props.data?.inst_1_in_musical === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_1_in_musical)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Postgraduate Loan</div>
                      <div className="value">
                        {this.props.data?.inst_1_in_postgrad === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_1_in_postgrad)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Other / Loan</div>
                      <div className="value">
                        {this.props.data?.inst_1_in_other === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_1_in_other)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-wrapper is-total">
                      <div className="label">TOTAL</div>
                      <div className="value">
                        {this.props.data?.inst_1_in_total === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_1_in_total)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="inst-2" className="field-group">
            <div className="field-group-header">Institution 2</div>
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 md:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Name of intended institution</div>
                  <div className="value">
                    {this.props.data?.inst_2_name_visible === null ? "" : this.props.data?.inst_2_name_visible}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Type of course</div>
                  <div className="value">
                    {this.props.data?.inst_2_course_type === null ? "" : this.props.data?.inst_2_course_type}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Title of course</div>
                  <div className="value">
                    {this.props.data?.inst_2_course_name === null ? "" : this.props.data?.inst_2_course_name}
                  </div>
                </div>
              </div>
              <div className="col-span-6 md:col-span-3">
                <div className="field-wrapper">
                  <div className="label">Length of course</div>
                  <div className="value">
                    {this.props.data?.inst_2_course_length === null ? "" : this.props.data?.inst_2_course_length}
                  </div>
                </div>
              </div>
              <div className="col-span-6 md:col-span-3">
                <div className="field-wrapper">
                  <div className="label">Related Year</div>
                  <div className="value">
                    {this.props.data?.inst_2_course_year === null ? "" : this.props.data?.inst_2_course_year}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-9">
                <div className="field-wrapper">
                  <div className="label">Name(s) of teacher(s)</div>
                  <div className="value">
                    {this.props.data?.inst_2_course_teachers === null ? "" : this.props.data?.inst_2_course_teachers}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-3">
                <div className="field-wrapper">
                  <div className="label">Application Status</div>
                  <div className="value">
                    {this.props.data?.inst_2_course_status === null ? "" : this.props.data?.inst_2_course_status}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="field-group-sub-header">Outgoings</div>
                <div className="grid grid-cols-12 gap-2">
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Tuition Fees</div>
                      <div className="value">
                        {this.props.data?.inst_2_out_fees === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_2_out_fees)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Rent</div>
                      <div className="value">
                        {this.props.data?.inst_2_out_rent === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_2_out_rent)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Maintenance</div>
                      <div className="value">
                        {this.props.data?.inst_2_out_maintenance === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_2_out_maintenance)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Additional Travel Costs</div>
                      <div className="value">
                        {this.props.data?.inst_2_out_travel === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_2_out_travel)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-wrapper is-total">
                      <div className="label">TOTAL</div>
                      <div className="value">
                        {this.props.data?.inst_2_out_total === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_2_out_total)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-wrapper">
                      <div className="label">Other Living Costs</div>
                      <div className="value textarea">
                        {this.props.data?.inst_2_out_info === null ? "" : this.props.data?.inst_2_out_info}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="field-group-sub-header">Incoming</div>
                <div className="grid grid-cols-12 gap-2">
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Scholarship / Bursary</div>
                      <div className="value">
                        {this.props.data?.inst_2_in_bursary === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_2_in_bursary)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Family Support</div>
                      <div className="value">
                        {this.props.data?.inst_2_in_family === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_2_in_family)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Personal Savings</div>
                      <div className="value">
                        {this.props.data?.inst_2_in_savings === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_2_in_savings)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Teaching</div>
                      <div className="value">
                        {this.props.data?.inst_2_in_teaching === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_2_in_teaching)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Earnings (non-musical)</div>
                      <div className="value">
                        {this.props.data?.inst_2_in_nonmusical === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_2_in_nonmusical)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Earnings (musical)</div>
                      <div className="value">
                        {this.props.data?.inst_2_in_musical === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_2_in_musical)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Postgraduate Loan</div>
                      <div className="value">
                        {this.props.data?.inst_2_in_postgrad === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_2_in_postgrad)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Other / Loan</div>
                      <div className="value">
                        {this.props.data?.inst_2_in_other === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_2_in_other)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-wrapper is-total">
                      <div className="label">TOTAL</div>
                      <div className="value">
                        {this.props.data?.inst_2_in_total === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.inst_2_in_total)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="ms-sc" className="field-group">
            <div className="field-group-header">Masterclass / Short Course</div>
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 md:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Teacher(s) Name(s)</div>
                  <div className="value">
                    {this.props.data?.private_study_teachers === null ? "" : this.props.data?.private_study_teachers}
                  </div>
                </div>
              </div>
              <div className="col-span-6 md:col-span-3">
                <div className="field-wrapper">
                  <div className="label">Number of lessons</div>
                  <div className="value">
                    {this.props.data?.private_study_lessons === null ? "" : this.props.data?.private_study_lessons}
                  </div>
                </div>
              </div>
              <div className="col-span-6 md:col-span-3">
                <div className="field-wrapper">
                  <div className="label">Application Status</div>
                  <div className="value">
                    {this.props.data?.private_study_status === null ? "" : this.props.data?.private_study_status}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Name of Short Course</div>
                  <div className="value">
                    {this.props.data?.short_course_name === null ? "" : this.props.data?.short_course_name}
                  </div>
                </div>
              </div>
              <div className="col-span-6 md:col-span-3">
                <div className="field-wrapper">
                  <div className="label">Length of Short Course</div>
                  <div className="value">
                    {this.props.data?.short_course_length === null ? "" : this.props.data?.short_course_length}
                  </div>
                </div>
              </div>
              <div className="col-span-6 md:col-span-3">
                <div className="field-wrapper">
                  <div className="label">Course Status</div>
                  <div className="value">
                    {this.props.data?.short_course_status === null ? "" : this.props.data?.short_course_status}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="field-group-sub-header">Outgoings</div>
                <div className="grid grid-cols-12 gap-2">
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Tuition Fees</div>
                      <div className="value">
                        {this.props.data?.ps_sc_out_fees === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.ps_sc_out_fees)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Rent</div>
                      <div className="value">
                        {this.props.data?.ps_sc_out_rent === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.ps_sc_out_rent)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Maintenance</div>
                      <div className="value">
                        {this.props.data?.ps_sc_out_maintenance === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.ps_sc_out_maintenance)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Additional Travel Costs</div>
                      <div className="value">
                        {this.props.data?.ps_sc_out_travel === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.ps_sc_out_travel)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-wrapper is-total">
                      <div className="label">TOTAL</div>
                      <div className="value">
                        {this.props.data?.ps_sc_out_total === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.ps_sc_out_total)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-wrapper">
                      <div className="label">Other Living Costs</div>
                      <div className="value textarea">
                        {this.props.data?.ps_sc_out_info === null ? "" : this.props.data?.ps_sc_out_info}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="field-group-sub-header">Incoming</div>
                <div className="grid grid-cols-12 gap-2">
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Scholarship / Bursary</div>
                      <div className="value">
                        {this.props.data?.ps_sc_in_bursary === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.ps_sc_in_bursary)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Family Support</div>
                      <div className="value">
                        {this.props.data?.ps_sc_in_family === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.ps_sc_in_family)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Personal Savings</div>
                      <div className="value">
                        {this.props.data?.ps_sc_in_savings === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.ps_sc_in_savings)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Teaching</div>
                      <div className="value">
                        {this.props.data?.ps_sc_in_teaching === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.ps_sc_in_teaching)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Earnings (non-musical)</div>
                      <div className="value">
                        {this.props.data?.ps_sc_in_nonmusical === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.ps_sc_in_nonmusical)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Earnings (musical)</div>
                      <div className="value">
                        {this.props.data?.ps_sc_in_musical === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.ps_sc_in_musical)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Postgraduate Loan</div>
                      <div className="value">
                        {this.props.data?.ps_sc_in_postgrad === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.ps_sc_in_postgrad)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Other / Loan</div>
                      <div className="value">
                        {this.props.data?.ps_sc_in_other === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.ps_sc_in_other)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-wrapper is-total">
                      <div className="label">TOTAL</div>
                      <div className="value">
                        {this.props.data?.ps_sc_in_total === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(this.props.data?.ps_sc_in_total)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="education-info" className="tab-content mt-10">
          <h1 className="text-4xl mb-5">Education</h1>
          <div className="field-group">
            <div className="field-group-header" style={{ marginTop: "0" }}>Current</div>
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 lg:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Institution Name</div>
                  <div className="value">
                    {this.props.data?.education_current_name_visible === null ? "" : this.props.data?.education_current_name_visible}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-4">
                <div className="field-wrapper">
                  <div className="label">Course Title</div>
                  <div className="value">
                    {this.props.data?.education_current_course_title === null ? "" : this.props.data?.education_current_course_title}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-2">
                <div className="field-wrapper">
                  <div className="label">Graduation Date</div>
                  <div className="value">
                    {this.props.data?.education_current_graduation_date === null ? "" : this.props.data?.education_current_graduation_date}
                  </div>
                </div>
              </div>
              <div className="col-span-12">
                <div className="field-group-sub-header">Not currently in education</div>
              </div>
              <div className="col-span-12">
                <div className="field-wrapper">
                  <div className="label">What are you doing?</div>
                  <div className="value">
                    {this.props.data?.education_not_studying === null ? "" : this.props.data?.education_not_studying}
                  </div>
                </div>
              </div>
              <div className="col-span-12">
                <div className="field-group-sub-header">Private Lessons</div>
                <div className="grid grid-cols-12 gap-5">
                  <div className="col-span-12 md:col-span-8">
                    <div className="field-wrapper">
                      <div className="label">Teachers</div>
                      <div className="value">
                        {this.props.data?.education_lessons_teachers === null ? "" : this.props.data?.education_lessons_teachers}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-4">
                    <div className="field-wrapper">
                      <div className="label">Regularity</div>
                      <div className="value capitalize">
                        {this.props.data?.education_lessons_regularity === null ? "" : this.props.data?.education_lessons_regularity}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="field-group">
            <div className="field-group-header">Undergraduate / Other Degree(s)</div>
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12">
                <div className="field-group-sub-header">Undergraduate</div>
              </div>
              <div className="col-span-12 lg:col-span-4">
                <div className="field-wrapper">
                  <div className="label">Institution Name</div>
                  <div className="value">
                    {this.props.data?.education_undergrad_name_visible === null ? "" : this.props.data?.education_undergrad_name_visible}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-4">
                <div className="field-wrapper">
                  <div className="label">Course Title</div>
                  <div className="value">
                    {this.props.data?.education_undergrad_course_title === null ? "" : this.props.data?.education_undergrad_course_title}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-3 lg:col-span-2">
                <div className="field-wrapper">
                  <div className="label">Graduation Date</div>
                  <div className="value">
                    {this.props.data?.education_undergrad_graduation_date === null ? "" : this.props.data?.education_undergrad_graduation_date}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-3 lg:col-span-2">
                <div className="field-wrapper">
                  <div className="label">Class</div>
                  <div className="value">
                    {this.props.data?.education_undergrad_class === null ? "" : this.props.data?.education_undergrad_class}
                  </div>
                </div>
              </div>
              <div className="col-span-12">
                <div className="field-group-sub-header">Other</div>
              </div>
              <div className="col-span-12 lg:col-span-4">
                <div className="field-wrapper">
                  <div className="label">Institution Name</div>
                  <div className="value">
                    {this.props.data?.education_other_name === null ? "" : this.props.data?.education_other_name}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-4">
                <div className="field-wrapper">
                  <div className="label">Course Title</div>
                  <div className="value">
                    {this.props.data?.education_other_course_title === null ? "" : this.props.data?.education_other_course_title}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-3 lg:col-span-2">
                <div className="field-wrapper">
                  <div className="label">Graduation Date</div>
                  <div className="value">
                    {this.props.data?.education_other_graduation_date === null ? "" : this.props.data?.education_other_graduation_date}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-3 lg:col-span-2">
                <div className="field-wrapper">
                  <div className="label">Class</div>
                  <div className="value">
                    {this.props.data?.education_other_class === null ? "" : this.props.data?.education_other_class}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="field-group">
            <div className="field-group-header">Secondary / Teachers</div>
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12">
                <div className="field-group-sub-header">Junior Music College</div>
              </div>
              <div className="col-span-12 md:col-span-8">
                <div className="field-wrapper">
                  <div className="label">College Name</div>
                  <div className="value">
                    {this.props.data?.education_college_name === null ? "" : this.props.data?.education_college_name}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-4">
                <div className="field-wrapper">
                  <div className="label">Dates Attended</div>
                  <div className="value">
                    {this.props.data?.education_college_dates === null ? "" : this.props.data?.education_college_dates}
                  </div>
                </div>
              </div>
              <div className="col-span-12">
                <div className="field-group-sub-header">Secondary School</div>
              </div>
              <div className="col-span-12 md:col-span-8">
                <div className="field-wrapper">
                  <div className="label">School 1 Name</div>
                  <div className="value">
                    {this.props.data?.education_school_1_name === null ? "" : this.props.data?.education_school_1_name}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-4">
                <div className="field-wrapper">
                  <div className="label">Dates Attended</div>
                  <div className="value">
                    {this.props.data?.education_school_1_dates === null ? "" : this.props.data?.education_school_1_dates}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-8">
                <div className="field-wrapper">
                  <div className="label">School 2 Name</div>
                  <div className="value">
                    {this.props.data?.education_school_2_name === null ? "" : this.props.data?.education_school_2_name}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-4">
                <div className="field-wrapper">
                  <div className="label">Dates Attended</div>
                  <div className="value">
                    {this.props.data?.education_school_2_dates === null ? "" : this.props.data?.education_school_2_dates}
                  </div>
                </div>
              </div>
              <div className="col-span-12">
                <div className="field-group-sub-header">Teachers</div>
              </div>
              <div className="col-span-12 md:col-span-8">
                <div className="field-wrapper">
                  <div className="label">Current Teacher(s) Name</div>
                  <div className="value">
                    {this.props.data?.education_teachers_current_name === null ? "" : this.props.data?.education_teachers_current_name}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-4">
                <div className="field-wrapper">
                  <div className="label">Current Teacher(s) Dates</div>
                  <div className="value">
                    {this.props.data?.education_teachers_current_dates === null ? "" : this.props.data?.education_teachers_current_dates}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-12">
                <div className="field-wrapper">
                  <div className="label">Additional Teachers</div>
                  <div className="value textarea">
                    {this.props.data?.education_teachers_other1_name === null || this.props.data?.education_teachers_other1_name == '' ? "" : this.props.data?.education_teachers_other1_name+" (" + this.props.data?.education_teachers_other1_dates + ")"}<br />
                    {this.props.data?.education_teachers_other2_name === null || this.props.data?.education_teachers_other2_name == '' ? "" : this.props.data?.education_teachers_other2_name+" (" + this.props.data?.education_teachers_other2_dates + ")"}<br />
                    {this.props.data?.education_teachers_other3_name === null || this.props.data?.education_teachers_other3_name == '' ? "" : this.props.data?.education_teachers_other3_name+" (" + this.props.data?.education_teachers_other3_dates + ")"}<br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="focus-info" className="tab-content mt-10">
          <h1 className="text-4xl mb-5">Focus</h1>
          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12 lg:col-span-6">
              <div className="field-wrapper">
                <div className="label">Main Focus of Studies</div>
                <div className="value textarea">
                {this.props.data?.education_focus === null ? "" : nl2br(this.props.data?.education_focus)}
                </div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6">
              <div className="field-wrapper">
                <div className="label">Most Significant Performances</div>
                <div className="value textarea">
                {this.props.data?.performances === null ? "" : nl2br(this.props.data?.performances)}
                </div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6">
              <div className="field-wrapper">
                <div className="label">Expect to Achieve from Studies</div>
                <div className="value textarea">
                {this.props.data?.study_aim === null ? "" : nl2br(this.props.data?.study_aim)}
                </div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6">
              <div className="field-wrapper">
                <div className="label">Career Aims</div>
                <div className="value textarea">
                {this.props.data?.career_aims === null ? "" : nl2br(this.props.data?.career_aims)}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="finance-info" className="tab-content mt-10">
          <h1 className="text-4xl mb-5">Finance</h1>
          <div className="field-group">
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 md:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Student Debt</div>
                  <div className="value">
                    {this.props.data?.financial_debt_student === null ? "" : new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP",
                      maximumFractionDigits: 0
                    }).format(this.props.data?.financial_debt_student)}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Other Debt</div>
                  <div className="value">
                    {this.props.data?.financial_debt_other === null ? "" : new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP",
                      maximumFractionDigits: 0
                    }).format(this.props.data?.financial_debt_other)}
                  </div>
                </div>
              </div>
              <div className="col-span-12">
                <div className="field-wrapper">
                  <div className="label">Repayment Terms</div>
                  <div className="value">
                    {this.props.data?.financial_debt_terms === null ? "" : this.props.data?.financial_debt_terms}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Current Funding</div>
                  <div className="value textarea">
                    {this.props.data?.financial_funding_current_name === null || this.props.data?.financial_funding_current_name == '' ? "" : this.props.data?.financial_funding_current_name+" - " + new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP",
                      maximumFractionDigits: 0
                    }).format(this.props.data?.financial_funding_current_amount)}<br />
                    {this.props.data?.financial_funding_current2_name === null || this.props.data?.financial_funding_current2_name == '' ? "" : this.props.data?.financial_funding_current2_name+" - " + new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP",
                      maximumFractionDigits: 0
                    }).format(this.props.data?.financial_funding_current2_amount)}<br />
                    {this.props.data?.financial_funding_current3_name === null || this.props.data?.financial_funding_current3_name == '' ? "" : this.props.data?.financial_funding_current3_name+" - " + new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP",
                      maximumFractionDigits: 0
                    }).format(this.props.data?.financial_funding_current3_amount)}<br />
                    {this.props.data?.financial_funding_current4_name === null || this.props.data?.financial_funding_current4_name == '' ? "" : this.props.data?.financial_funding_current4_name+" - " + new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP",
                      maximumFractionDigits: 0
                    }).format(this.props.data?.financial_funding_current4_amount)}<br />
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Future Funding</div>
                  <div className="value textarea">
                    {this.props.data?.financial_funding_applied_name === null || this.props.data?.financial_funding_applied_name == '' ? "" : this.props.data?.financial_funding_applied_name+" - " + new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP",
                      maximumFractionDigits: 0
                    }).format(this.props.data?.financial_funding_applied_amount)}<br />
                    {this.props.data?.financial_funding_applied2_name === null || this.props.data?.financial_funding_applied2_name == '' ? "" : this.props.data?.financial_funding_applied2_name+" - " + new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP",
                      maximumFractionDigits: 0
                    }).format(this.props.data?.financial_funding_applied2_amount)}<br />
                    {this.props.data?.financial_funding_applied3_name === null || this.props.data?.financial_funding_applied3_name == '' ? "" : this.props.data?.financial_funding_applied3_name+" - " + new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP",
                      maximumFractionDigits: 0
                    }).format(this.props.data?.financial_funding_applied3_amount)}<br />
                    {this.props.data?.financial_funding_applied4_name === null || this.props.data?.financial_funding_applied4_name == '' ? "" : this.props.data?.financial_funding_applied4_name+" - " + new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP",
                      maximumFractionDigits: 0
                    }).format(this.props.data?.financial_funding_applied4_amount)}<br />
                    {this.props.data?.financial_funding_applied5_name === null || this.props.data?.financial_funding_applied5_name == '' ? "" : this.props.data?.financial_funding_applied5_name+" - " + new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP",
                      maximumFractionDigits: 0
                    }).format(this.props.data?.financial_funding_applied5_amount)}<br />
                    {this.props.data?.financial_funding_applied6_name === null || this.props.data?.financial_funding_applied6_name == '' ? "" : this.props.data?.financial_funding_applied6_name+" - " + new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP",
                      maximumFractionDigits: 0
                    }).format(this.props.data?.financial_funding_applied6_amount)}<br />
                  </div>
                </div>
              </div>
              <div className="col-span-12">
                <div className="field-wrapper">
                  <div className="label">Other information relevant to application</div>
                  <div className="value textarea">
                    {this.props.data?.other_relevant_info === null ? "" : nl2br(this.props.data?.other_relevant_info)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="media-info" className="tab-content mt-10">
          <h1 className="text-4xl mb-5">Media</h1>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 lg:col-span-6">
              <div className="field-group">
                <div className="field-group-header" style={{ marginTop: "0" }}>Recording 1</div>
                <div className="grid grid-cols-12 gap-2">
                  <div className="col-span-12 lg:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Composer</div>
                      <div className="value">{" "}
                        {this.props.data?.media_recording1_composer === null ? "" : this.props.data?.media_recording1_composer}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Name of Piece</div>
                      <div className="value">{" "}
                        {this.props.data?.media_recording1_name === null ? "" : this.props.data?.media_recording1_name}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-wrapper">
                      <div className="label">Video</div>
                      <div className="value">{" "}
                        {this.props.data?.media_recording1_url === null ? "" : this.props.data?.media_recording1_url}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6">
              <div className="field-group">
                <div className="field-group-header" style={{ marginTop: "0" }}>Recording 2</div>
                <div className="grid grid-cols-12 gap-2">
                  <div className="col-span-12 lg:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Composer</div>
                      <div className="value">{" "}
                        {this.props.data?.media_recording2_composer === null ? "" : this.props.data?.media_recording2_composer}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Name of Piece</div>
                      <div className="value">{" "}
                        {this.props.data?.media_recording2_name === null ? "" : this.props.data?.media_recording2_name}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-wrapper">
                      <div className="label">Video</div>
                      <div className="value">{" "}
                        {this.props.data?.media_recording2_url === null ? "" : this.props.data?.media_recording2_url}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="references-info" className="tab-content mt-10">
          <h1 className="text-4xl mb-5">References</h1>
          <div id="referee-1" className="field-group">
            <div className="field-group-header" style={{ marginTop: "0" }}>Referee 1</div>
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 md:col-span-6 lg:col-span-3">
                <div className="field-wrapper">
                  <div className="label">Name</div>
                  <div className="value">
                    {this.props.data?.referee_1_name === null ? "Not Set" : this.props.data?.referee_1_name}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-3">
                <div className="field-wrapper">
                  <div className="label">Position</div>
                  <div className="value">
                    {this.props.data?.referee_1_position === null ? "Not Set" : this.props.data?.referee_1_position}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-3">
                <div className="field-wrapper">
                  <div className="label">Email Address</div>
                  <div className="value">
                    {this.props.data?.referee_1_email === null ? "" : this.props.data?.referee_1_email}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-3">
                <div className="field-wrapper">
                  <div className="label">Phone Number</div>
                  <div className="value">
                    {this.props.data?.referee_1_phone === null ? "" : this.props.data?.referee_1_phone}
                  </div>
                </div>
              </div>
              <div className="col-span-12">
                <div className="field-wrapper">
                  <div className="label">Reference</div>
                  <div className="value">
                    {this.props.data?.referee_1_reference === null ? "" : nl2br(this.props.data?.referee_1_reference)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="referee-2" className="field-group">
            <div className="field-group-header">Referee 2</div>
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 md:col-span-6 lg:col-span-3">
                <div className="field-wrapper">
                  <div className="label">Name</div>
                  <div className="value">
                    {this.props.data?.referee_2_name === null ? "Not Set" : this.props.data?.referee_2_name}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-3">
                <div className="field-wrapper">
                  <div className="label">Position</div>
                  <div className="value">
                    {this.props.data?.referee_2_position === null ? "Not Set" : this.props.data?.referee_2_position}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-3">
                <div className="field-wrapper">
                  <div className="label">Email Address</div>
                  <div className="value">
                    {this.props.data?.referee_2_email === null ? "" : this.props.data?.referee_2_email}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-3">
                <div className="field-wrapper">
                  <div className="label">Phone Number</div>
                  <div className="value">
                    {this.props.data?.referee_2_phone === null ? "" : this.props.data?.referee_2_phone}
                  </div>
                </div>
              </div>
              <div className="col-span-12">
                <div className="field-wrapper">
                  <div className="label">Reference</div>
                  <div className="value">
                    {this.props.data?.referee_2_reference === null ? "" : nl2br(this.props.data?.referee_2_reference)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const DataFields = ({ name, value }) => {
    return (
      <div className=" mt-0 mr-3 md:my-2">
        <p>{name} </p>
        <p className=" bg-light-grey py-2 px-5 ">
          {value === null ? "N/L" : value}
        </p>
      </div>
    );
  };
