import React from "react";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";

function News({ data }) {
  return (
    <Link to={`/news/${data.ID}`} className="item news">
      <div className="">
        <div className="flex">
          <div className="flex-shrink-0">
            {data.img && (
              <img
                className="w-28 h-full object-cover mb-5 md:mb-0 md:h-full md:w-48"
                src={data.img}
              />
            )}
          </div>
          <div className="item-padding">
            <h4 className="mb-1 item-title">
              {data.title}
            </h4>
            <div className="item-date">{data.date}</div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default News;
