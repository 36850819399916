import { createContext, useReducer } from "react";
import { LOGIN, LOGOUT } from "./types";

export const AuthContext = createContext(null);

const initialState = {
  token: localStorage.getItem("token"),
  display_name: localStorage.getItem("display_name"),
  email: localStorage.getItem("email"),
  first_name: localStorage.getItem("first_name"),
  id: localStorage.getItem("id"),
  last_name: localStorage.getItem("last_name"),
  nickname: localStorage.getItem("nickname"),
  last_login_friendly: localStorage.getItem("last_login_friendly"),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        token: action.payload.token,
        display_name: action.payload.display_name,
        email: action.payload.email,
        first_name: action.payload.first_name,
        id: action.payload.id,
        last_name: action.payload.last_name,
        nickname: action.payload.nickname,
        last_login_friendly: action.payload.last_login_friendly,
      };

    case LOGOUT:
      localStorage.clear();
      return {
        ...state,
        token: localStorage.removeItem("token"),
        display_name: localStorage.removeItem("display_name"),
        email: localStorage.removeItem("email"),
        first_name: localStorage.removeItem("first_name"),
        id: localStorage.removeItem("id"),
        last_name: localStorage.removeItem("last_name"),
        nickname: localStorage.removeItem("nickname"),
        last_login_friendly:localStorage.removeItem("last_login_friendly"),
      };

    default:
      return state;
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
