import React from "react";
import Youtube from "./youtube";
export class ComponentToPrint extends React.PureComponent {
  render() {
    console.log("props", this.props);
    return (
      <div className="m-5 md:m-10 text-primary_text">
        <div className="flex justify-between">
          <div>
            <h1 className="text-2xl  md:text-4xl font-bold ">
              {this.props.data?.last_name}
            </h1>
          </div>
        </div>
        <div className="flex justify-start flex-wrap">
          <div className="w-full md:w-60 mt-0 mr-3 md:my-2">
            <p>A Number</p>
            <p className=" bg-light-grey py-2 px-5 ">
              {" "}
              {this.props.data?.a_number}
            </p>
          </div>
          <div className="w-full md:w-96 mt-0 mr-3 md:my-2">
            <p>Instrument </p>
            <p className=" bg-light-grey py-2 px-5 ">
              {" "}
              {this.props.data?.instrument}
            </p>
          </div>
          <div className="w-full md:w-60 mt-0 mr-3 md:my-2">
            <p>Age </p>
            <p className=" bg-light-grey py-2 px-5 ">
              {" "}
              {this.props.data?.age === null ? "N/L" : this.props.data?.age}
            </p>
          </div>
          <div className="w-full md:w-60 mt-0 md:my-2">
            <p>Year </p>
            <p className=" bg-light-grey py-2 px-5 ">
              {" "}
              {this.props.data?.year === null ? "N/L" : this.props.data?.year}
            </p>
          </div>
        </div>
        <h1 className="text-4xl">Personal </h1>
        <div className="flex justify-start flex-wrap">
          <div className="w-full md:w-60 mt-0 mr-3 md:my-2">
            <p>Nationality</p>
            <p className=" bg-light-grey py-2 px-5 ">
              {" "}
              {this.props.data?.nationality === null
                ? "N/L"
                : this.props.data?.nationality}
            </p>
          </div>
          <div className="w-full md:w-96 mt-0 mr-3 md:my-2">
            <p>Date of birth </p>
            <p className=" bg-light-grey py-2 px-5 ">
              {" "}
              {this.props.data?.dob === null ? "N/L" : this.props.data?.dob}
            </p>
          </div>
          <div className="w-full md:w-60 mt-0 mr-3 md:my-2">
            {/* <p>Marital Status </p>
                <p className=" bg-light-grey py-2 px-5 ">
                  {" "}
                  {this.props.data?.marital_status  === null ? "N/L" : this.props.data?.marital_status}
                </p> */}
          </div>
          <div className="w-full md:w-60 mt-0 md:my-2">
            <p>Year </p>
            <p className=" bg-light-grey py-2 px-5 ">
              {" "}
              {this.props.data?.year === null ? "N/L" : this.props.data?.year}
            </p>
          </div>
        </div>
        <div className="flex justify-start flex-wrap">
          <div className="w-full md:w-60 mt-0 mr-3 md:my-2">
            <p>Telephone</p>
            <p className=" bg-light-grey py-2 px-5 ">
              {" "}
              {this.props.data?.phone === null ? "N/L" : this.props.data?.phone}
            </p>
          </div>
          {this.props.data?.mobile && (
            <div className="w-full md:w-96 mt-0 mr-3 md:my-2">
              <p>Mobile </p>
              <p className=" bg-light-grey py-2 px-5 ">
                {" "}
                {this.props.data?.mobile}
              </p>
            </div>
          )}
          <div className="w-full md:w-60 mt-0 md:my-2">
            <p>Email </p>
            <p className=" bg-light-grey py-2 px-5 ">
              {" "}
              {this.props.data?.email === null ? "N/L" : this.props.data?.email}
            </p>
          </div>
        </div>
        <h1 className="text-4xl">Course</h1>
        <p className="border-b-2">Course One</p>
            <div className="flex justify-start flex-wrap">
              <DataFields
                name="inst 1 course length"
                value={this.props.data?.inst_1_course_length}
              />
              <DataFields
                name="inst 1 course name"
                value={this.props.data?.inst_1_course_name}
              />
              <DataFields
                name="inst 1 course status"
                value={this.props.data?.inst_1_course_status}
              />
              <DataFields
                name="inst 1 course teacher"
                value={this.props.data?.inst_1_course_teachers}
              />
              <DataFields
                name="inst 1 course type"
                value={this.props.data?.inst_1_course_type}
              />
              <DataFields
                name="inst 1 course year"
                value={this.props.data?.inst_1_course_year}
              />
              <DataFields
                name="inst 1 in bursary"
                value={this.props.data?.inst_1_in_bursary}
              />

              <DataFields
                name="inst 1 in family"
                value={this.props.data?.inst_1_in_family}
              />
              <DataFields
                name="inst 1 in musical"
                value={this.props.data?.inst_1_in_musical}
              />
              <DataFields
                name="inst 1 in nonmusical"
                value={this.props.data?.inst_1_in_nonmusical}
              />
              <DataFields
                name="inst 1 in other"
                value={this.props.data?.inst_1_in_other}
              />
              <DataFields
                name="inst 1 in savings"
                value={this.props.data?.inst_1_in_savings}
              />
              <DataFields
                name="inst 1 in teaching"
                value={this.props.data?.inst_1_in_teaching}
              />
              <DataFields
                name="inst 1 in total"
                value={this.props.data?.inst_1_in_total}
              />
              <DataFields name="inst 1 course name" value={this.props.data?.inst_1_name} />
              <DataFields
                name="inst 1 out out fees"
                value={this.props.data?.inst_1_out_fees}
              />
              <DataFields
                name="inst 1 out info"
                value={this.props.data?.inst_1_out_info}
              />
              <DataFields
                name="inst 1 out maintenance"
                value={this.props.data?.inst_1_out_maintenance}
              />
              <DataFields
                name="inst 1 out rent"
                value={this.props.data?.inst_1_out_rent}
              />
              <DataFields
                name="inst 1 out total"
                value={this.props.data?.inst_1_out_total}
              />
              <DataFields
                name="inst 1 out travel"
                value={this.props.data?.inst_1_out_travel}
              />
            </div>
            <p className="border-b-2">Course Two</p>
            <div className="flex justify-start flex-wrap">
              <DataFields
                name="inst 2 course length"
                value={this.props.data?.inst_2_course_length}
              />
              <DataFields
                name="inst 2 course name"
                value={this.props.data?.inst_2_course_name}
              />
              <DataFields
                name="inst 2 course status"
                value={this.props.data?.inst_2_course_status}
              />
              <DataFields
                name="inst 2 course teacher"
                value={this.props.data?.inst_2_course_teachers}
              />
              <DataFields
                name="inst 2 course type"
                value={this.props.data?.inst_2_course_type}
              />
              <DataFields
                name="inst 2 course year"
                value={this.props.data?.inst_2_course_year}
              />
              <DataFields
                name="inst 2 in bursary"
                value={this.props.data?.inst_2_in_bursary}
              />

              <DataFields
                name="inst 2 in family"
                value={this.props.data?.inst_2_in_family}
              />
              <DataFields
                name="inst 2 in musical"
                value={this.props.data?.inst_2_in_musical}
              />
              <DataFields
                name="inst 2 in nonmusical"
                value={this.props.data?.inst_2_in_nonmusical}
              />
              <DataFields
                name="inst 2 in other"
                value={this.props.data?.inst_2_in_other}
              />
              <DataFields
                name="inst 2 in savings"
                value={this.props.data?.inst_2_in_savings}
              />
              <DataFields
                name="inst 2 in teaching"
                value={this.props.data?.inst_2_in_teaching}
              />
              <DataFields
                name="inst 2 in total"
                value={this.props.data?.inst_2_in_total}
              />
              <DataFields name="inst 2 course name" value={this.props.data?.inst_2_name} />
              <DataFields
                name="inst 2 out out fees"
                value={this.props.data?.inst_2_out_fees}
              />
              <DataFields
                name="inst 2 out info"
                value={this.props.data?.inst_2_out_info}
              />
              <DataFields
                name="inst 2 out maintenance"
                value={this.props.data?.inst_2_out_maintenance}
              />
              <DataFields
                name="inst 2 out rent"
                value={this.props.data?.inst_2_out_rent}
              />
              <DataFields
                name="inst 2 out total"
                value={this.props.data?.inst_2_out_total}
              />
              <DataFields
                name="inst 2 out travel"
                value={this.props.data?.inst_2_out_travel}
              />
            </div>
            <h1 className="text-4xl">Education</h1>
            <div className="flex justify-start flex-wrap">
              <DataFields
                name="Education current course title"
                value={this.props.data?.education_current_course_title}
              />
              <DataFields
                name="Education current graduation date"
                value={this.props.data?.education_current_graduation_date}
              />

              <DataFields
                name="Education current name"
                value={this.props.data?.education_current_name}
              />
              <DataFields
                name="Education focus"
                value={this.props.data?.education_focus}
              />
              <DataFields
                name="Education lessons"
                value={this.props.data?.education_lessons}
              />
              <DataFields
                name="Education lessons regularity"
                value={this.props.data?.education_lessons_regularity}
              />
              <DataFields
                name="Education lessons teachers"
                value={this.props.data?.education_lessons_teachers}
              />
              <DataFields
                name="Education not studying"
                value={this.props.data?.education_not_studying}
              />
              <DataFields
                name="Education other class"
                value={this.props.data?.education_other_class}
              />
              <DataFields
                name="Education other course title"
                value={this.props.data?.education_other_course_title}
              />
              <DataFields
                name="Education other name"
                value={this.props.data?.education_other_name}
              />
              <DataFields
                name="Education other graduation date"
                value={this.props.data?.education_other_graduation_date}
              />
              <DataFields
                name="Education other graduation date"
                value={this.props.data?.education_other_graduation_date}
              />
              <DataFields
                name="Education other name"
                value={this.props.data?.education_other_name}
              />
              <DataFields
                name="Education undergrad class"
                value={this.props.data?.education_undergrad_class}
              />
              <DataFields
                name="Education undergrad course title"
                value={this.props.data?.education_undergrad_course_title}
              />
              <DataFields
                name="Education undergrad graduation date"
                value={this.props.data?.education_undergrad_graduation_date}
              />
              <DataFields
                name="Education undergrad name"
                value={this.props.data?.education_undergrad_name}
              />
            </div>

            <div className="flex flex-start flex-wrap">
              <div>
                <DataFields
                  name="Education school 1 name"
                  value={this.props.data?.education_school_1_name}
                />
                <DataFields
                  name="Education school 1 dates"
                  value={this.props.data?.education_school_1_dates}
                />
                <DataFields
                  name="Education school 2 name"
                  value={this.props.data?.education_school_2_name}
                />

                <DataFields
                  name="Education school 2 dates"
                  value={this.props.data?.education_school_2_dates}
                />
              </div>
              <div>
                <DataFields
                  name="Education teachers current name"
                  value={this.props.data?.education_teachers_current_name}
                />
                <DataFields
                  name="Education teachers current dates"
                  value={this.props.data?.education_teachers_current_dates}
                />

                <DataFields
                  name="Education teachers other 1 name"
                  value={this.props.data?.education_teachers_other1_name}
                />
                <DataFields
                  name="Education teachers other 1 dates"
                  value={this.props.data?.education_teachers_other1_dates}
                />

                <DataFields
                  name="Education teachers other 2 name"
                  value={this.props.data?.education_teachers_other2_name}
                />
                <DataFields
                  name="Education teachers other 2 dates"
                  value={this.props.data?.education_teachers_other2_dates}
                />

                <DataFields
                  name="Education teachers other 3 name"
                  value={this.props.data?.education_teachers_other3_name}
                />
                <DataFields
                  name="Education teachers other 3 dates"
                  value={this.props.data?.education_teachers_other3_dates}
                />
              </div>
            </div>
            <h1 className="text-4xl">References</h1>
            <div className="flex justify-start flex-wrap">
              <DataFields
                name="Referee 1 email"
                value={this.props.data?.referee_1_email}
              />
              <DataFields name="Referee 1 name" value={this.props.data?.referee_1_name} />
              <DataFields
                name="Referee 1 phone"
                value={this.props.data?.referee_1_phone}
              />
              <DataFields
                name="Referee 1 position"
                value={this.props.data?.referee_1_position}
              />
              <DataFields
                name="Referee 1 reference"
                value={this.props.data?.referee_1_reference}
              />
              <DataFields
                name="Referee 2 email"
                value={this.props.data?.referee_2_email}
              />
              <DataFields name="Referee 2 name" value={this.props.data?.referee_2_name} />
              <DataFields
                name="Referee 2 phone"
                value={this.props.data?.referee_2_phone}
              />
              <DataFields
                name="Referee 2 position"
                value={this.props.data?.referee_2_position}
              />
              <DataFields
                name="Referee 2 reference"
                value={this.props.data?.referee_2_reference}
              />
              <DataFields
                name="References complete"
                value={this.props.data?.references_complete}
              />
            </div>
            <h1 className="text-4xl">Financial</h1>
            <div className="flex justify-start flex-wrap">
              <DataFields
                name="Financial debt other"
                value={this.props.data?.financial_debt_other}
              />
              <DataFields
                name="Financial debt student"
                value={this.props.data?.financial_debt_student}
              />

              <DataFields
                name="Financial debt terms"
                value={this.props.data?.financial_debt_terms}
              />
            </div>

            <div className="flex flex-start flex-wrap">
              <div>
                <DataFields
                  name="Financial funding applied name"
                  value={this.props.data?.financial_funding_applied_name}
                />
                <DataFields
                  name="Financial funding applied amount"
                  value={this.props.data?.financial_funding_applied_amount}
                />
                <DataFields
                  name="Financial funding applied 2 name"
                  value={this.props.data?.financial_funding_applied2_name}
                />

                <DataFields
                  name="Financial funding applied 2 amount"
                  value={this.props.data?.financial_funding_applied2_amount}
                />
                <DataFields
                  name="Financial funding applied 3 name"
                  value={this.props.data?.financial_funding_applied3_name}
                />

                <DataFields
                  name="Financial funding applied 3 amount"
                  value={this.props.data?.financial_funding_applied3_amount}
                />
                <DataFields
                  name="Financial funding applied 4 name"
                  value={this.props.data?.financial_funding_applied4_name}
                />

                <DataFields
                  name="Financial funding applied 4 amount"
                  value={this.props.data?.financial_funding_applied4_amount}
                />
                <DataFields
                  name="Financial funding applied 5 name"
                  value={this.props.data?.financial_funding_applied5_name}
                />
                <DataFields
                  name="Financial funding applied 5 amount"
                  value={this.props.data?.financial_funding_applied5_amount}
                />
                <DataFields
                  name="Financial_funding applied 6 name"
                  value={this.props.data?.financial_funding_applied6_name}
                />
                <DataFields
                  name="Financial funding applied 6 amount"
                  value={this.props.data?.financial_funding_applied6_amount}
                />
              </div>
              <div>
                <DataFields
                  name="Financial funding current name"
                  value={this.props.data?.financial_funding_current_name}
                />
                <DataFields
                  name="Financial funding current amount"
                  value={this.props.data?.financial_funding_current_amount}
                />
                <DataFields
                  name="Financial funding current 2 name"
                  value={this.props.data?.financial_funding_current2_name}
                />
                <DataFields
                  name="Financial funding current 2 amount"
                  value={this.props.data?.financial_funding_current2_amount}
                />

                <DataFields
                  name="Financial funding current 3 name"
                  value={this.props.data?.financial_funding_current3_name}
                />
                <DataFields
                  name="Financial funding current 3 amount"
                  value={this.props.data?.financial_funding_current3_amount}
                />
                <DataFields
                  name="Financial funding current 4 name"
                  value={this.props.data?.financial_funding_current4_name}
                />
                <DataFields
                  name="Financial funding current 4 amount"
                  value={this.props.data?.financial_funding_current4_amount}
                />
              </div>
            </div>
            <h1 className="text-4xl">Recording</h1>
            <div className="mt-10">
            {this.props.data?.media_recording1_url && (
              <Youtube videoURL={this.props.data?.media_recording1_url} />
            )}
            {this.props.data?.media_recording2_url && (
              <Youtube videoURL={this.props.data?.media_recording2_url} />
            )}
          </div>
      </div>
    );
  }
}

const DataFields = ({ name, value }) => {
    return (
      <div className=" mt-0 mr-3 md:my-2">
        <p>{name} </p>
        <p className=" bg-light-grey py-2 px-5 ">
          {value === null ? "N/L" : value}
        </p>
      </div>
    );
  };
