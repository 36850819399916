import React, { useState, useRef, useEffect, useCallback } from "react";
import ReactToPrint from "react-to-print";
import { useParams } from "react-router-dom";
import Layout from "../layout";
import { IoIosArrowBack } from "react-icons/io";
import { AiFillPrinter } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import api from "../../service/axios";
import Loading from "../loading";
import OfflineScreen from "../offline";

function SingleNews() {
  const { id } = useParams();
  const [news, setNews] = useState();
  const [print, setPrint] = useState(false);
  const [foundRecordInCache, setFoundRecordInCache] = useState(false);
  const componentRef = useRef();
  const user_id = localStorage.getItem("id");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [img, setImg] = useState();

  const getCurrentNews = useCallback(async () => {
    try {
      const { data } = await api.get(`/trusteenews/${id}?user=${user_id}`);
      localStorage.setItem("news-" + id, JSON.stringify(data));
      console.log({ data });
      return { result: data };
    } catch (error) {
      setError(true);
    }
  }, [id]);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const { result } = await getCurrentNews();
      setData(result);
      setImg(result.img);
      console.log({img: img});
      setLoading(false);
    }
    if (navigator.onLine) {
    fetchData();
  } else if (localStorage.getItem("news-" + id) !== null) {
      console.log('is offline');
      setData(JSON.parse(localStorage.getItem("news-" + id)));
      setData(JSON.parse(localStorage.getItem("news-" + id)));
      console.log(JSON.parse(localStorage.getItem("news-" + id)));
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  }, [id, getCurrentNews]);

  var loadedClass = (loading) ? '' : 'loaded';
  if (error) {
    return <OfflineScreen />;
  }

  return (
    <Layout>
      {loading && <Loading />}
      <div id="content"
      className={`${loadedClass}`}
      >
        <div className="flex">
          <div className="flex-grow">
            <div className="page-sub-header">Trustee News</div>
            <h1 className="text-2xl md:text-4xl font-bold mr-5">{data?.title}</h1>
            <p className="text-xs mb-4 text-gray-400">
              {data?.date}
            </p>
          </div>
          <div className="shrink flex flex-nowrap self-start">
            <Link to="/news">
              <p className="button br0" >
                <IoIosArrowBack size={24} />
                <span className="ml2 hidden md:inline-block"> Back</span>
              </p>
            </Link>
            {print && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setPrint(false)}>
                    &times;
                  </span>
                  <div>
                    <ReactToPrint
                      trigger={() => (
                        <button
                          className="button br0"
                          onClick={() => console.log({ trigger: "false" })}
                        >
                          Print this out!
                        </button>
                      )}
                      content={() => componentRef.current}
                    />
                    <ComponentToPrint ref={componentRef} data={data} />
                  </div>
                </div>
              </div>
            )}
            <button
              onClick={() => setPrint(true)}
              className="button br0 hidden md:inline-flex ml-5"
            ><AiFillPrinter size={24} /></button>
          </div>
        </div>

          <div>
            {data && (
              <div className="news-article-wrapper">
                {img ? (<img width="100%" src={img} className="featured-image"/>) : ''}
                <div
                  className="news-text-wrapper"
                  dangerouslySetInnerHTML={{ __html: data.content }}
                />
              </div>
            )}
          </div>
        </div>
    </Layout>
  );
}

export default SingleNews;

class ComponentToPrint extends React.PureComponent {
  render() {
    console.log("props", this.props);
    return (
      <div>
        {this.props.data && (
          <div className="news-article-wrapper p-8 print">
            <h1 className="text-4xl font-bold">{this.props.data.title}</h1>
            <p className="text-xs mb-4 text-gray-400 font-bold">
              {this.props.data.date}
            </p>
            {this.props.data.img ? (<img width="100%" src={this.props.data.img} className="featured-image"/>) : ''}
            <div
              className="text-1 "
              dangerouslySetInnerHTML={{ __html: this.props.data.content }}
            />
          </div>
        )}
      </div>
    );
  }
}
