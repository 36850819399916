import React, { useEffect } from "react";
import { useState } from "react";
import Footer from "../footer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Layout({ children }) {
  const [mode, setMode] = useState("");
  const notifyUpdateToast = () => toast.info("An App update is available.  Click to install",{
    position: "top-center",
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    onClose: () => {
      localStorage.removeItem('update')
      navigator.serviceWorker.ready.then( registration => {
        console.log('registration');
        console.log(registration);
        if (registration.waiting != null) {
          registration.waiting.postMessage({ action: 'skipWaiting' });
        }
      });
    }
  });

  useEffect(() => {
    if (navigator.onLine) {
      if(localStorage.getItem('update')){
        notifyUpdateToast();
        console.log("Update found please reload the page ")
      }
      setMode("ONLINE");
    } else {
      setMode("OFFLINE");
    }
  }, [mode]);

  return (
    <div id="content-wrapper">
      <ToastContainer limit={1}/>
      {mode === "OFFLINE" && (
        <div id="offline-banner"
        >
          YOU ARE OFFLINE!{" "}
          <span className="ml-3">Content may not be up-to-date </span>
        </div>
      )}

      {children}
      <div className="fixed bottom-0">
        <Footer />
      </div>
    </div>
  );
}

export default Layout;
