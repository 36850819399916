import React, { useState,  useEffect, useCallback } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import Layout from "../layout";
import Pagination from "react-js-pagination";
import ApplicationTable from "./table";
import api from "../../service/axios";
import Loading from "../loading";
import { RiFilter3Fill } from "react-icons/ri";
import OfflineScreen from "../offline";
import useWindowDimensions from "../../hooks/useWindowDimension";

function Applications() {
  const { height } = useWindowDimensions();
  const [filterOpen, setFilterOpen] = useState(false);
  const [discipline, setDiscipline] = useState([]);
  const [instrument, setInstrument] = useState([]);
  //const [count, setCount, writeError] = useLocalStorageState('counter', 0);
  const [aNumber, setANumber] = useLocalStorage("aNumber", "");
  const [view, setView] = useLocalStorage("view", "");
  const [disc, setDisc] = useLocalStorage("disc", "");
  const [inst, setInst] = useLocalStorage("inst", "");
  //console.log('Height: ' + height);
  //false asc and true desc
  const [headerKey, setHeaderKey] = useState({ header: "", sort: false });
  // const [recordFound, setRecordFound] = useState(false);
  const [applications, setApplications] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [totalRecord, setTotalRecord] = useState();
  const [page, setPage] = useLocalStorage("page", 1);
  const [perPage, setPerPage] = useLocalStorage("perPage", 20); // Default 20
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [calls, setCalls] = useState(0); // Testing how many times this gets called

  const getInitialData = useCallback(
    async (page) => {
      //console.log('getInitialData: ' + calls);
      try {
        setCalls(calls + 1);
        const user_id = localStorage.getItem("id");
        const aNumber = (localStorage.getItem("aNumber") !='""') ? JSON.parse(localStorage.getItem("aNumber")) : '';
        const discipline = (localStorage.getItem("discipline") !='""') ? JSON.parse(localStorage.getItem("discipline")) : '';
        const inst = (localStorage.getItem("inst") !='""') ? JSON.parse(localStorage.getItem("inst")) : '';
        const read = (localStorage.getItem("read") !='""') ? JSON.parse(localStorage.getItem("read")) : '';
        //http request
        const { data, headers } = await api.get(
          `/studygrant?user=${user_id}&search=${aNumber}&read=${view}&discipline=${disc}&instrument=${inst}&page=${page}`
        );
        //console.log({inst:inst});
        //console.log({query:`/studygrant?user=${user_id}&search=${aNumber}&read=${view}&discipline=${disc}&instrument=${inst}&page=${page}`});
        //get data from local storage
        setApplications(data.items);
        setDisciplines(data.disciplines);
        setInstruments(data.instruments);
        setTotalRecord(headers["x-wp-total"]);
        //set data to the local storage
        localStorage.setItem("applications", JSON.stringify(data));
        localStorage.setItem("totalApp", parseInt(headers["x-wp-total"]));
        setLoading(false);
        return { data };
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    },
    [perPage]
  );

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const { data } = await getInitialData(page);
      //console.log('useEffect');
      //set discipline category
      //const uniqueDiscipline = [...new Set(data.map(i.discipline =>  i.discipline_friendly))];
      //setDiscipline(uniqueDiscipline);
    }

    if (navigator.onLine) {
      fetchData();
    } else {
      if (localStorage.getItem("applications") === null) {
        setError(true);
        setLoading(false);
        return;
      } else {
        setApplications(JSON.parse(localStorage.getItem("applications")).items);
        setDisciplines(JSON.parse(localStorage.getItem("applications")).disciplines);
        setInstruments(JSON.parse(localStorage.getItem("applications")).instruments);
        setTotalRecord(localStorage.getItem("totalApp"));
        setLoading(false);
        //console.log('Loading complete');
      }
    }
  }, [getInitialData, page]);

  const handlePageChange = async (page) => {
    setPage(page);
    await getInitialData(page);
  };

  const FilterSearch = useCallback(async () => {
    setLoading(true);
    setPage(1);
    // let result;
    const user_id = localStorage.getItem("id");
    const aNumber = (localStorage.getItem("aNumber") !='""') ? JSON.parse(localStorage.getItem("aNumber")) : '';
    const discipline = (localStorage.getItem("discipline") !='""') ? JSON.parse(localStorage.getItem("discipline")) : '';
    const inst = (localStorage.getItem("inst") !='""') ? JSON.parse(localStorage.getItem("inst")) : '';
    const read = (localStorage.getItem("read") !='""') ? JSON.parse(localStorage.getItem("read")) : '';
    const { data, headers } = await api.get(
      `/studygrant?user=${user_id}&search=${aNumber}&read=${view}&discipline=${disc}&instrument=${inst}&page=1`
    );
    setApplications(data.items);
    setDisciplines(data.disciplines);
    setInstruments(data.instruments);
    setTotalRecord(headers["x-wp-total"]);
    setLoading(false);
  }, [view, aNumber, disc, inst]);

  const ClearSearch = async () => {
    setLoading(true);
    setANumber("");
    setDisc("");
    setInst("");
    setView("");
    setPage(1);
    // let result;
    const user_id = localStorage.getItem("id");
    const { data, headers } = await api.get(
      `/studygrant?user=${user_id}&search=&read=&discipline=&instrument=`
    );
    setApplications(data.items);
    setDisciplines(data.disciplines);
    setInstruments(data.instruments);
    setTotalRecord(headers["x-wp-total"]);
    setLoading(false);
  };

  /*const sortByHeaderKey = async (sortedByField) => {
    console.log("sortByHeaderKey ", sortedByField);
    setOrderby(sortedByField);
    if (orderdir === 'asc') {
      setOrderdir('desc');
    } else {
      setOrderdir('asc');
    }
    //setHeaderKey({ header: sortedByField, sort: !headerKey.sort });

    fetchSortedData(sortedByField);
  };*/
  /*const fetchSortedData = async (orderby) => {
    setLoading(true);
    const user_id = localStorage.getItem("id");
    const { data } = await api.get(
      `/studygrant?user=${user_id}&search=${aNumber}&read=${view}&discipline=${disc}&instrument=${inst}&order_by=${orderby}&order=${orderdir}&page=${page}`
    );
    console.log("sorted asc data",orderdir)
    setApplications(data.items);
    setDisciplines(data.disciplines);
    setInstruments(data.instruments);
    // setApplications(JSON.parse(localStorage.getItem("applications")));
    setLoading(false);
  };*/
  if (error) {
    return <OfflineScreen />;
  }
  var loadedClass = (loading) ? '' : 'loaded';
  return (
    <Layout>
      {loading && <Loading />}
      <div id="content"
      className={`${loadedClass}`}
      >
        <div className="flex justify-between">
        <div>
          <h1 className=" text-2xl md:text-4xl font-bold ">
            Grant Applications
          </h1>
          {aNumber != '' || disc !='' || inst != '' || view !='' ? <p className="page-sub-header">Filters Active</p> : ''}
        </div>

          <div
            onClick={() => setFilterOpen(!filterOpen)}
            className="flex button py-2 px-4 text-footer_color cursor-pointer br0 place-self-start"
          >
            <RiFilter3Fill size={24} /> <span className="ml2 hidden md:inline-block"> Filter</span>
          </div>
        </div>
        {filterOpen && (
          <div id="filter" className="w-full bg-light-grey p-5 mt-8">
            <h3 className="mb-5">Applications Filter</h3>
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 md:col-span-6 lg:col-span-3">
                <input
                value={aNumber}
                onChange={(e) => setANumber(e.target.value)}
                className="w-full bg-filter_input_color py-3 px-4 "
                placeholder="Enter name or A Number here..."
                />
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-3">
                <select
                  onChange={(e) => setDisc(e.target.value)}
                  value = {disc}
                  className="w-full bg-filter_input_color py-3 px-4 "
                >
                  <option key="0" value="">Select Discipline </option>
                  {disciplines && Object.keys(disciplines).map(function(key) {
                      return <option key={key} value={key}>{disciplines[key]}</option>
                    })
                  }
                </select>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-3">
                <select
                  onChange={(e) => setInst(e.target.value)}
                  value = {inst}
                  className="w-full bg-filter_input_color py-3 px-4 "
                >
                  <option value="">Select Instrument </option>
                  {instruments && Object.keys(instruments).map(function(key) {
                      return <option key={key} value={key}>{instruments[key]}</option>
                    })
                  }
                </select>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-3">
                <select
                  onChange={(e) => setView(e.target.value)}
                  value = {view}
                  className="w-full bg-filter_input_color py-3 px-4 "
                >
                  <option value="">Show read & unread</option>
                  <option key="read" value="read"> Read</option>
                  <option key="unread" value="unread"> Unread</option>
                </select>
              </div>
            </div>
            <div className="flex flex-wrap mt-5">
              <button
                onClick={FilterSearch}
                className=" button py-4 px-8 font-bold text-footer_color cursor-pointer"
              >
                Filter Now
              </button>
              <button
                onClick={ClearSearch}
                className="ml-5 button-small place-self-end"
              >
                Clear Filter
              </button>
            </div>
          </div>
        )}
        <div className="flex justify-between bg-light-grey mt-3 p-2 mt-5 items-center">
          <p>
            Page {page} of {" "}
            {totalRecord ? Math.ceil(Number(totalRecord) / perPage) : 0}
          </p>
          {applications && (
            <Pagination
              activePage={page}
              itemsCountPerPage={perPage}
              totalItemsCount={Number(totalRecord)}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClassFirst = 'pagination-item first'
              itemClassLast = 'pagination-item last'
              itemClassPrev = 'pagination-item prev'
              itemClassNext = 'pagination-item next'
              firstPageText = '<<'
              lastPageText = '>>'
              nextPageText = 'Next >'
              prevPageText = '< Prev'
            />
          )}
        </div>
        <div className="overflow-x-scroll md:overflow-hidden">
          {applications && (
            <ApplicationTable
              data={applications}
            />
          )}
          {applications=='' && <h3 className="text-center mt-3 mb-3">No applications</h3>}
        </div>
        <div className="flex justify-between bg-light-grey mt-3 p-2 items-center">
          <p>
            Page {page} /{" "}
            {totalRecord ? Math.ceil(Number(totalRecord) / perPage) : 0}
          </p>
          {applications && (
            <Pagination
              activePage={page}
              itemsCountPerPage={perPage}
              totalItemsCount={Number(totalRecord)}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClassFirst = 'pagination-item first'
              itemClassLast = 'pagination-item last'
              itemClassPrev = 'pagination-item prev'
              itemClassNext = 'pagination-item next'
              firstPageText = '<<'
              lastPageText = '>>'
              nextPageText = 'Next >'
              prevPageText = '< Prev'
            />
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Applications;
