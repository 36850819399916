export default function swDev() {
  let swUrl = `${process.env.PUBLIC_URL}/sw.js`;
  if ('serviceWorker' in navigator) {

    navigator.serviceWorker.register(swUrl).then(reg => {
      reg.addEventListener('updatefound', () => {
        // A wild service worker has appeared in reg.installing!
        window.newWorker = reg.installing;

        window.newWorker.addEventListener('statechange', () => {
          // Has network.state changed?
          switch (window.newWorker.state) {
            case 'installed':
              if (navigator.serviceWorker.controller) {
                // new update available
                localStorage.setItem('update',true)
                console.log('Show update bar');
              }
              // No update available
              break;
          }
        });
      });
    });

    let refreshing;
    navigator.serviceWorker.addEventListener('controllerchange', function () {
      if (refreshing) return;
      window.location.reload();
      refreshing = true;
    });
  }
  /*navigator.serviceWorker.register(swUrl).then((result) => {
    console.log("Server worker is running", result);
    result.onupdatefound = () => {
      console.log("update found... swDev", result);
      const installingWorker = result.installing; //This returns null
      if (installingWorker) {
        console.log("installingServiceWorker!!!");
        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              console.log("New content is available; please refresh.");
              localStorage.setItem('update',true)
              // alert("Content updated please reload the page");
              // window.location.reload();
            } else {
              console.log("Content is cached for offline use.");
            }
          }
        };
      }
    };
  });*/
}
