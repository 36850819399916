import React, { useState, useRef, useEffect, useCallback } from "react";
import Layout from "../layout";
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import Embed from 'react-tiny-oembed'
import ReactToPrint from "react-to-print";
import Loading from "../loading";
import { AiFillPrinter } from "react-icons/ai";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import api from "../../service/axios";
// import Loading from "../loading";
import Youtube from "./youtube";
import { ComponentToPrint } from "./componentToPrint";
import OfflineScreen from "../offline";
const nl2br = require('react-nl2br');

function Application() {
  //const navigate = useNavigate();
  const history = useHistory();
  const { id } = useParams();
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [print, setPrint] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState();
  const [calls, setCalls] = useState(1); // Testing how many times this gets called
  let {result} = useState();

  const getCurrentApplication = useCallback(async () => {
    setCalls(calls + 1);
    console.log('calls: ' + calls);
    try {
      const user_id = localStorage.getItem("id");
      const { data } = await api.get(`/studygrant/${id}?user=${user_id}`);
      localStorage.setItem("studygrant-" + id, JSON.stringify(data));
      console.log({ data });
      return { result: data };
    } catch (error) {
      setError(true);
    }
  }, [id]);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const { result } = await getCurrentApplication();
      setData(result);
      setLoading(false);
    }
    if (navigator.onLine) {
    fetchData();
    } else if (localStorage.getItem("studygrant-" + id) !== null) {
      console.log('is offline');
      setData(JSON.parse(localStorage.getItem("studygrant-" + id)));
      console.log(JSON.parse(localStorage.getItem("studygrant-" + id)));
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  }, [id, getCurrentApplication]);

  const [tab, setTab] = useState(1);
  if (error) {
    return <OfflineScreen />;
  }
  var loadedClass = (loading) ? '' : 'loaded';
  return (
    <Layout>
      {loading && <Loading />}
      <div id="content"
      className={`${loadedClass}`}
      >
        <div className="flex">
          <div className="flex-grow">
            <div className="page-sub-header">Grant Application</div>
            <h1 className="text-2xl  md:text-4xl font-bold ">
              <span className="uppercase">{data?.last_name}</span> {data?.first_name}
            </h1>
          </div>
          <div className="shrink">
            <button
              onClick={() => history.goBack()}
              className="button br0"><IoIosArrowBack size={24} />
            <span className="ml2 hidden md:inline-block"> Back</span>
            </button>
            {print && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setPrint(false)}>
                    &times;
                  </span>
                  <div>
                    <ReactToPrint
                      trigger={() => (
                        <button
                          className="button br0"
                          onClick={() => console.log({ trigger: "false" })}
                        >
                          Print this out!
                        </button>
                      )}
                      content={() => componentRef.current}
                    />
                    <ComponentToPrint ref={componentRef} data={data} />
                  </div>
                </div>
              </div>
            )}
            <button
              onClick={() => setPrint(true)}
              className="button br0 hidden md:inline-flex ml-5"
            ><AiFillPrinter size={24} /></button>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-2 mt-5">
          <div className="col-span-6 md:col-span-2">
            <div className="field-wrapper">
              <div className="label">A Number</div>
              <div className="value">{data?.a_number === null ? "" : data?.a_number}</div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="field-wrapper">
              <div className="label">Instrument</div>
              <div className="value">{data?.instrument_friendly === null ? "" : data?.instrument_friendly}</div>
            </div>
          </div>
          <div className="col-span-6 md:col-span-2">
            <div className="field-wrapper">
              <div className="label">Age</div>
              <div className="value">{data?.age === null ? "" : data?.age}</div>
            </div>
          </div>
          <div className="col-span-6 md:col-span-2">
            <div className="field-wrapper">
              <div className="label">Year</div>
              <div className="value">{data?.year === null ? "" : data?.year}</div>
            </div>
          </div>
          <div className="col-span-6 md:col-span-2">
            <div className="field-wrapper">
              <div className="label">Required</div>
              <div className="value">
                {data?.required_1 === null ? "" : new Intl.NumberFormat("en-GB", {
                  style: "currency",
                  currency: "GBP",
                  maximumFractionDigits: 0
                }).format(data?.required_1)}
              </div>
            </div>
          </div>
          <div className="col-span-6 md:col-span-2">
            <div className="field-wrapper">
              <div className="label">&nbsp;</div>
              <div className="value">
                {data?.required_2 === null ? "" : new Intl.NumberFormat("en-GB", {
                  style: "currency",
                  currency: "GBP",
                  maximumFractionDigits: 0
                }).format(data?.required_2)}
              </div>
            </div>
          </div>
        </div>
        <div className="md:hidden tab-selector-wrapper">
          <select name="" id="" onChange={event => setTab(event.target.options.selectedIndex + 1)}>
            <option value="1">Personal</option>
            <option value="2">Course</option>
            <option value="3">Education</option>
            <option value="4">Focus</option>
            <option value="5">Finance</option>
            <option value="6">Media</option>
            <option value="7">References</option>
          </select>
        </div>
        <div className="overflow-x-scroll md:overflow-hidden mt-5 tabs-wrapper hidden md:flex">
          <div
            onClick={() => setTab(1)}
            className={
              tab === 1
                ? "tab active"
                : "tab"
            }
          >
            Personal
          </div>
          <div
            onClick={() => setTab(2)}
            className={
              tab === 2
                ? "tab active"
                : "tab"
            }
          >
            Course
          </div>
          <div
            onClick={() => setTab(3)}
            className={
              tab === 3
                ? "tab active"
                : "tab"
            }
          >
            Education
          </div>
          <div
            onClick={() => setTab(4)}
            className={
              tab === 4
                ? "tab active"
                : "tab"
            }
          >
            Focus
          </div>
          <div
            onClick={() => setTab(5)}
            className={
              tab === 5
                ? "tab active"
                : "tab"
            }
          >
            Finance
          </div>
          <div
          onClick={() => setTab(6)}
          className={
            tab === 6
            ? "tab active"
            : "tab"
          }
          >
          Media
          </div>
          <div
            onClick={() => setTab(7)}
            className={
              tab === 7
                ? "tab active"
                : "tab"
            }
          >
            References
            </div>

        </div>
        {tab === 1 && (
          <>
            <div id="personal-tab" className="tab-content">
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 md:col-span-4">
                <div className="field-wrapper">
                  <div className="label">Nationality</div>
                  <div className="value">
                    {data?.nationality === null ? "" : data?.nationality}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-2">
                <div className="field-wrapper">
                  <div className="label">Date of birth</div>
                  <div className="value">
                    {data?.dob === null ? "" : data?.dob}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Previous Surname</div>
                  <div className="value">
                    {data?.previous_surname === null ? "" : data?.previous_surname}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-4">
                <div className="field-wrapper">
                  <div className="label">Phone</div>
                  <div className="value">
                    {data?.phone === null ? "" : data?.phone}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-8">
                <div className="field-wrapper">
                  <div className="label">Email</div>
                  <div className="value">
                    {data?.email === null ? "" : data?.email}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Permanent Address</div>
                  <div className="value">
                    {data?.address_permanent === null ? "" : nl2br(data?.address_permanent)}
                    <br/>
                    {data?.address_permanent_city === null ? "" : data?.address_permanent_city}
                    <br/>
                    {data?.address_permanent_postcode === null ? "" : data?.address_permanent_postcode}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Term Time Address</div>
                  <div className="value">
                    {data?.address_term === null ? "" : nl2br(data?.address_term)}
                    <br/>
                    {data?.address_term_city === null ? "" : data?.address_term_city}
                    <br/>
                    {data?.address_term_postcode === null ? "" : data?.address_term_postcode}
                  </div>
                </div>
              </div>
            </div>
            </div>
          </>
        )}
        {tab === 2 && (
          <>
          <div id="course-tab" className="tab-content">
            <div id="inst-1" className="field-group">
              <div className="field-group-header" style={{ marginTop: "0" }}>Institution 1</div>
              <div className="grid grid-cols-12 gap-5">
                <div className="col-span-12 md:col-span-6">
                  <div className="field-wrapper">
                    <div className="label">Name of intended institution</div>
                    <div className="value">
                      {data?.inst_1_name_visible === null ? "" : data?.inst_1_name_visible}
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-6">
                  <div className="field-wrapper">
                    <div className="label">Type of course</div>
                    <div className="value">
                      {data?.inst_1_course_type === null ? "" : data?.inst_1_course_type}
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-6">
                  <div className="field-wrapper">
                    <div className="label">Title of course</div>
                    <div className="value">
                      {data?.inst_1_course_name === null ? "" : data?.inst_1_course_name}
                    </div>
                  </div>
                </div>
                <div className="col-span-6 md:col-span-3">
                  <div className="field-wrapper">
                    <div className="label">Length of course</div>
                    <div className="value">
                      {data?.inst_1_course_length === null ? "" : data?.inst_1_course_length}
                    </div>
                  </div>
                </div>
                <div className="col-span-6 md:col-span-3">
                  <div className="field-wrapper">
                    <div className="label">Related Year</div>
                    <div className="value">
                      {data?.inst_1_course_year === null ? "" : data?.inst_1_course_year}
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-9">
                  <div className="field-wrapper">
                    <div className="label">Name(s) of teacher(s)</div>
                    <div className="value">
                      {data?.inst_1_course_teachers === null ? "" : data?.inst_1_course_teachers}
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-3">
                  <div className="field-wrapper">
                    <div className="label">Application Status</div>
                    <div className="value">
                      {data?.inst_1_course_status === null ? "" : data?.inst_1_course_status}
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-6">
                  <div className="field-group-sub-header">Outgoings</div>
                  <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Tuition Fees</div>
                        <div className="value">
                          {data?.inst_1_out_fees === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_1_out_fees)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Rent</div>
                        <div className="value">
                          {data?.inst_1_out_rent === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_1_out_rent)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Maintenance</div>
                        <div className="value">
                          {data?.inst_1_out_maintenance === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_1_out_maintenance)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Additional Travel Costs</div>
                        <div className="value">
                          {data?.inst_1_out_travel === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_1_out_travel)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="field-wrapper is-total">
                        <div className="label">TOTAL</div>
                        <div className="value">
                          {data?.inst_1_out_total === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_1_out_total)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="field-wrapper">
                        <div className="label">Other Living Costs</div>
                        <div className="value textarea">
                          {data?.inst_1_out_info === null ? "" : data?.inst_1_out_info}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-6">
                  <div className="field-group-sub-header">Incoming</div>
                  <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Scholarship / Bursary</div>
                        <div className="value">
                          {data?.inst_1_in_bursary === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_1_in_bursary)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Family Support</div>
                        <div className="value">
                          {data?.inst_1_in_family === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_1_in_family)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Personal Savings</div>
                        <div className="value">
                          {data?.inst_1_in_savings === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_1_in_savings)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Teaching</div>
                        <div className="value">
                          {data?.inst_1_in_teaching === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_1_in_teaching)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Earnings (non-musical)</div>
                        <div className="value">
                          {data?.inst_1_in_nonmusical === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_1_in_nonmusical)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Earnings (musical)</div>
                        <div className="value">
                          {data?.inst_1_in_musical === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_1_in_musical)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Postgraduate Loan</div>
                        <div className="value">
                          {data?.inst_1_in_postgrad === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_1_in_postgrad)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Other / Loan</div>
                        <div className="value">
                          {data?.inst_1_in_other === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_1_in_other)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="field-wrapper is-total">
                        <div className="label">TOTAL</div>
                        <div className="value">
                          {data?.inst_1_in_total === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_1_in_total)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="inst-2" className="field-group">
              <div className="field-group-header">Institution 2</div>
              <div className="grid grid-cols-12 gap-5">
                <div className="col-span-12 md:col-span-6">
                  <div className="field-wrapper">
                    <div className="label">Name of intended institution</div>
                    <div className="value">
                      {data?.inst_2_name_visible === null ? "" : data?.inst_2_name_visible}
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-6">
                  <div className="field-wrapper">
                    <div className="label">Type of course</div>
                    <div className="value">
                      {data?.inst_2_course_type === null ? "" : data?.inst_2_course_type}
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-6">
                  <div className="field-wrapper">
                    <div className="label">Title of course</div>
                    <div className="value">
                      {data?.inst_2_course_name === null ? "" : data?.inst_2_course_name}
                    </div>
                  </div>
                </div>
                <div className="col-span-6 md:col-span-3">
                  <div className="field-wrapper">
                    <div className="label">Length of course</div>
                    <div className="value">
                      {data?.inst_2_course_length === null ? "" : data?.inst_2_course_length}
                    </div>
                  </div>
                </div>
                <div className="col-span-6 md:col-span-3">
                  <div className="field-wrapper">
                    <div className="label">Related Year</div>
                    <div className="value">
                      {data?.inst_2_course_year === null ? "" : data?.inst_2_course_year}
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-9">
                  <div className="field-wrapper">
                    <div className="label">Name(s) of teacher(s)</div>
                    <div className="value">
                      {data?.inst_2_course_teachers === null ? "" : data?.inst_2_course_teachers}
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-3">
                  <div className="field-wrapper">
                    <div className="label">Application Status</div>
                    <div className="value">
                      {data?.inst_2_course_status === null ? "" : data?.inst_2_course_status}
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-6">
                  <div className="field-group-sub-header">Outgoings</div>
                  <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Tuition Fees</div>
                        <div className="value">
                          {data?.inst_2_out_fees === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_2_out_fees)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Rent</div>
                        <div className="value">
                          {data?.inst_2_out_rent === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_2_out_rent)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Maintenance</div>
                        <div className="value">
                          {data?.inst_2_out_maintenance === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_2_out_maintenance)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Additional Travel Costs</div>
                        <div className="value">
                          {data?.inst_2_out_travel === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_2_out_travel)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="field-wrapper is-total">
                        <div className="label">TOTAL</div>
                        <div className="value">
                          {data?.inst_2_out_total === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_2_out_total)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="field-wrapper">
                        <div className="label">Other Living Costs</div>
                        <div className="value textarea">
                          {data?.inst_2_out_info === null ? "" : data?.inst_2_out_info}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-6">
                  <div className="field-group-sub-header">Incoming</div>
                  <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Scholarship / Bursary</div>
                        <div className="value">
                          {data?.inst_2_in_bursary === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_2_in_bursary)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Family Support</div>
                        <div className="value">
                          {data?.inst_2_in_family === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_2_in_family)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Personal Savings</div>
                        <div className="value">
                          {data?.inst_2_in_savings === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_2_in_savings)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Teaching</div>
                        <div className="value">
                          {data?.inst_2_in_teaching === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_2_in_teaching)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Earnings (non-musical)</div>
                        <div className="value">
                          {data?.inst_2_in_nonmusical === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_2_in_nonmusical)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Earnings (musical)</div>
                        <div className="value">
                          {data?.inst_2_in_musical === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_2_in_musical)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Postgraduate Loan</div>
                        <div className="value">
                          {data?.inst_2_in_postgrad === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_2_in_postgrad)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Other / Loan</div>
                        <div className="value">
                          {data?.inst_2_in_other === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_2_in_other)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="field-wrapper is-total">
                        <div className="label">TOTAL</div>
                        <div className="value">
                          {data?.inst_2_in_total === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.inst_2_in_total)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="ms-sc" className="field-group">
              <div className="field-group-header">Masterclass / Short Course</div>
              <div className="grid grid-cols-12 gap-5">
                <div className="col-span-12 md:col-span-6">
                  <div className="field-wrapper">
                    <div className="label">Teacher(s) Name(s)</div>
                    <div className="value">
                      {data?.private_study_teachers === null ? "" : data?.private_study_teachers}
                    </div>
                  </div>
                </div>
                <div className="col-span-6 md:col-span-3">
                  <div className="field-wrapper">
                    <div className="label">Number of lessons</div>
                    <div className="value">
                      {data?.private_study_lessons === null ? "" : data?.private_study_lessons}
                    </div>
                  </div>
                </div>
                <div className="col-span-6 md:col-span-3">
                  <div className="field-wrapper">
                    <div className="label">Application Status</div>
                    <div className="value">
                      {data?.private_study_status === null ? "" : data?.private_study_status}
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-6">
                  <div className="field-wrapper">
                    <div className="label">Name of Short Course</div>
                    <div className="value">
                      {data?.short_course_name === null ? "" : data?.short_course_name}
                    </div>
                  </div>
                </div>
                <div className="col-span-6 md:col-span-3">
                  <div className="field-wrapper">
                    <div className="label">Length of Short Course</div>
                    <div className="value">
                      {data?.short_course_length === null ? "" : data?.short_course_length}
                    </div>
                  </div>
                </div>
                <div className="col-span-6 md:col-span-3">
                  <div className="field-wrapper">
                    <div className="label">Course Status</div>
                    <div className="value">
                      {data?.short_course_status === null ? "" : data?.short_course_status}
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-6">
                  <div className="field-group-sub-header">Outgoings</div>
                  <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Tuition Fees</div>
                        <div className="value">
                          {data?.ps_sc_out_fees === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.ps_sc_out_fees)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Rent</div>
                        <div className="value">
                          {data?.ps_sc_out_rent === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.ps_sc_out_rent)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Maintenance</div>
                        <div className="value">
                          {data?.ps_sc_out_maintenance === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.ps_sc_out_maintenance)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Additional Travel Costs</div>
                        <div className="value">
                          {data?.ps_sc_out_travel === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.ps_sc_out_travel)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="field-wrapper is-total">
                        <div className="label">TOTAL</div>
                        <div className="value">
                          {data?.ps_sc_out_total === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.ps_sc_out_total)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="field-wrapper">
                        <div className="label">Other Living Costs</div>
                        <div className="value textarea">
                          {data?.ps_sc_out_info === null ? "" : data?.ps_sc_out_info}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-6">
                  <div className="field-group-sub-header">Incoming</div>
                  <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Scholarship / Bursary</div>
                        <div className="value">
                          {data?.ps_sc_in_bursary === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.ps_sc_in_bursary)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Family Support</div>
                        <div className="value">
                          {data?.ps_sc_in_family === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.ps_sc_in_family)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Personal Savings</div>
                        <div className="value">
                          {data?.ps_sc_in_savings === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.ps_sc_in_savings)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Teaching</div>
                        <div className="value">
                          {data?.ps_sc_in_teaching === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.ps_sc_in_teaching)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Earnings (non-musical)</div>
                        <div className="value">
                          {data?.ps_sc_in_nonmusical === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.ps_sc_in_nonmusical)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Earnings (musical)</div>
                        <div className="value">
                          {data?.ps_sc_in_musical === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.ps_sc_in_musical)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Postgraduate Loan</div>
                        <div className="value">
                          {data?.ps_sc_in_postgrad === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.ps_sc_in_postgrad)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Other / Loan</div>
                        <div className="value">
                          {data?.ps_sc_in_other === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.ps_sc_in_other)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="field-wrapper is-total">
                        <div className="label">TOTAL</div>
                        <div className="value">
                          {data?.ps_sc_in_total === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.ps_sc_in_total)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </>
        )}
        {tab === 3 && (
          <>
            <div className="tab-content">
              <div className="field-group">
                <div className="field-group-header" style={{ marginTop: "0" }}>Current</div>
                <div className="grid grid-cols-12 gap-5">
                  <div className="col-span-12 lg:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Institution Name</div>
                      <div className="value">
                        {data?.education_current_name_visible === null ? "" : data?.education_current_name_visible}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-4">
                    <div className="field-wrapper">
                      <div className="label">Course Title</div>
                      <div className="value">
                        {data?.education_current_course_title === null ? "" : data?.education_current_course_title}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-2">
                    <div className="field-wrapper">
                      <div className="label">Graduation Date</div>
                      <div className="value">
                        {data?.education_current_graduation_date === null ? "" : data?.education_current_graduation_date}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-group-sub-header">Not currently in education</div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-wrapper">
                      <div className="label">What are you doing?</div>
                      <div className="value">
                        {data?.education_not_studying === null ? "" : data?.education_not_studying}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-group-sub-header">Private Lessons</div>
                    <div className="grid grid-cols-12 gap-5">
                      <div className="col-span-12 md:col-span-8">
                        <div className="field-wrapper">
                          <div className="label">Teachers</div>
                          <div className="value">
                            {data?.education_lessons_teachers === null ? "" : data?.education_lessons_teachers}
                          </div>
                        </div>
                      </div>
                      <div className="col-span-12 md:col-span-4">
                        <div className="field-wrapper">
                          <div className="label">Regularity</div>
                          <div className="value capitalize">
                            {data?.education_lessons_regularity === null ? "" : data?.education_lessons_regularity}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="field-group">
                <div className="field-group-header">Undergraduate / Other Degree(s)</div>
                <div className="grid grid-cols-12 gap-5">
                  <div className="col-span-12">
                    <div className="field-group-sub-header">Undergraduate</div>
                  </div>
                  <div className="col-span-12 lg:col-span-4">
                    <div className="field-wrapper">
                      <div className="label">Institution Name</div>
                      <div className="value">
                        {data?.education_undergrad_name_visible === null ? "" : data?.education_undergrad_name_visible}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-4">
                    <div className="field-wrapper">
                      <div className="label">Course Title</div>
                      <div className="value">
                        {data?.education_undergrad_course_title === null ? "" : data?.education_undergrad_course_title}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-3 lg:col-span-2">
                    <div className="field-wrapper">
                      <div className="label">Graduation Date</div>
                      <div className="value">
                        {data?.education_undergrad_graduation_date === null ? "" : data?.education_undergrad_graduation_date}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-3 lg:col-span-2">
                    <div className="field-wrapper">
                      <div className="label">Class</div>
                      <div className="value">
                        {data?.education_undergrad_class === null ? "" : data?.education_undergrad_class}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-group-sub-header">Other</div>
                  </div>
                  <div className="col-span-12 lg:col-span-4">
                    <div className="field-wrapper">
                      <div className="label">Institution Name</div>
                      <div className="value">
                        {data?.education_other_name === null ? "" : data?.education_other_name}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-4">
                    <div className="field-wrapper">
                      <div className="label">Course Title</div>
                      <div className="value">
                        {data?.education_other_course_title === null ? "" : data?.education_other_course_title}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-3 lg:col-span-2">
                    <div className="field-wrapper">
                      <div className="label">Graduation Date</div>
                      <div className="value">
                        {data?.education_other_graduation_date === null ? "" : data?.education_other_graduation_date}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-3 lg:col-span-2">
                    <div className="field-wrapper">
                      <div className="label">Class</div>
                      <div className="value">
                        {data?.education_other_class === null ? "" : data?.education_other_class}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="field-group">
                <div className="field-group-header">Secondary / Teachers</div>
                <div className="grid grid-cols-12 gap-5">
                  <div className="col-span-12">
                    <div className="field-group-sub-header">Junior Music College</div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-wrapper">
                      <div className="label">College Name</div>
                      <div className="value">
                        {data?.education_college_name === null ? "" : data?.education_college_name}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-group-sub-header">Secondary School</div>
                  </div>
                  <div className="col-span-12 md:col-span-8">
                    <div className="field-wrapper">
                      <div className="label">School 1 Name</div>
                      <div className="value">
                        {data?.education_school_1_name === null ? "" : data?.education_school_1_name}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-4">
                    <div className="field-wrapper">
                      <div className="label">Dates Attended</div>
                      <div className="value">
                        {data?.education_school_1_dates === null ? "" : data?.education_school_1_dates}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-8">
                    <div className="field-wrapper">
                      <div className="label">School 2 Name</div>
                      <div className="value">
                        {data?.education_school_2_name === null ? "" : data?.education_school_2_name}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-4">
                    <div className="field-wrapper">
                      <div className="label">Dates Attended</div>
                      <div className="value">
                        {data?.education_school_2_dates === null ? "" : data?.education_school_2_dates}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-group-sub-header">Teachers</div>
                  </div>
                  <div className="col-span-12 md:col-span-8">
                    <div className="field-wrapper">
                      <div className="label">Current Teacher(s) Name</div>
                      <div className="value">
                        {data?.education_teachers_current_name === null ? "" : data?.education_teachers_current_name}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-4">
                    <div className="field-wrapper">
                      <div className="label">Current Teacher(s) Dates</div>
                      <div className="value">
                        {data?.education_teachers_current_dates === null ? "" : data?.education_teachers_current_dates}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-12">
                    <div className="field-wrapper">
                      <div className="label">Additional Teachers</div>
                      <div className="value textarea">
                        {data?.education_teachers_other1_name === null || data?.education_teachers_other1_name == '' ? "" : data?.education_teachers_other1_name+" (" + data?.education_teachers_other1_dates + ")"}<br />
                        {data?.education_teachers_other2_name === null || data?.education_teachers_other2_name == '' ? "" : data?.education_teachers_other2_name+" (" + data?.education_teachers_other2_dates + ")"}<br />
                        {data?.education_teachers_other3_name === null || data?.education_teachers_other3_name == '' ? "" : data?.education_teachers_other3_name+" (" + data?.education_teachers_other3_dates + ")"}<br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {tab === 4 && (
          <div className="tab-content">
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 lg:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Main Focus of Studies</div>
                  <div className="value textarea">
                  {data?.education_focus === null ? "Not set" : nl2br(data?.education_focus)}
                  </div>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Most Significant Performances</div>
                  <div className="value textarea">
                  {data?.performances === null ? "Not set" : nl2br(data?.performances)}
                  </div>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Expect to Achieve from Studies</div>
                  <div className="value textarea">
                  {data?.study_aim === null ? "Not set" : nl2br(data?.study_aim)}
                  </div>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Career Aims</div>
                  <div className="value textarea">
                  {data?.career_aims === null ? "Not set" : nl2br(data?.career_aims)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {tab === 5 && (
          <>
            <div id="" className="tab-content">
              <div className="field-group">
                <div className="grid grid-cols-12 gap-5">
                  <div className="col-span-12 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Student Debt</div>
                      <div className="value">
                        {data?.financial_debt_student === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(data?.financial_debt_student)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Other Debt</div>
                      <div className="value">
                        {data?.financial_debt_other === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(data?.financial_debt_other)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-wrapper">
                      <div className="label">Repayment Terms</div>
                      <div className="value">
                        {data?.financial_debt_terms === null ? "" : data?.financial_debt_terms}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Current Funding</div>
                      <div className="value textarea">
                        {data?.financial_funding_current_name === null || data?.financial_funding_current_name == '' ? "" : data?.financial_funding_current_name+" - " + new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(data?.financial_funding_current_amount)}<br />
                        {data?.financial_funding_current2_name === null || data?.financial_funding_current2_name == '' ? "" : data?.financial_funding_current2_name+" - " + new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(data?.financial_funding_current2_amount)}<br />
                        {data?.financial_funding_current3_name === null || data?.financial_funding_current3_name == '' ? "" : data?.financial_funding_current3_name+" - " + new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(data?.financial_funding_current3_amount)}<br />
                        {data?.financial_funding_current4_name === null || data?.financial_funding_current4_name == '' ? "" : data?.financial_funding_current4_name+" - " + new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(data?.financial_funding_current4_amount)}<br />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Future Funding</div>
                      <div className="value textarea">
                        {data?.financial_funding_applied_name === null || data?.financial_funding_applied_name == '' ? "" : data?.financial_funding_applied_name+" - " + new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(data?.financial_funding_applied_amount)}<br />
                        {data?.financial_funding_applied2_name === null || data?.financial_funding_applied2_name == '' ? "" : data?.financial_funding_applied2_name+" - " + new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(data?.financial_funding_applied2_amount)}<br />
                        {data?.financial_funding_applied3_name === null || data?.financial_funding_applied3_name == '' ? "" : data?.financial_funding_applied3_name+" - " + new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(data?.financial_funding_applied3_amount)}<br />
                        {data?.financial_funding_applied4_name === null || data?.financial_funding_applied4_name == '' ? "" : data?.financial_funding_applied4_name+" - " + new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(data?.financial_funding_applied4_amount)}<br />
                        {data?.financial_funding_applied5_name === null || data?.financial_funding_applied5_name == '' ? "" : data?.financial_funding_applied5_name+" - " + new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(data?.financial_funding_applied5_amount)}<br />
                        {data?.financial_funding_applied6_name === null || data?.financial_funding_applied6_name == '' ? "" : data?.financial_funding_applied6_name+" - " + new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(data?.financial_funding_applied6_amount)}<br />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-wrapper">
                      <div className="label">Other information relevant to application</div>
                      <div className="value textarea">
                        {data?.other_relevant_info === null ? "" : nl2br(data?.other_relevant_info)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {tab === 6 && (
          <div className="tab-content">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 lg:col-span-6">
                <div className="field-group">
                  <div className="field-group-header" style={{ marginTop: "0" }}>Recording 1</div>
                  <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-12 lg:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Composer</div>
                        <div className="value">{" "}
                          {data?.media_recording1_composer === null ? "Not set" : data?.media_recording1_composer}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Name of Piece</div>
                        <div className="value">{" "}
                          {data?.media_recording1_name === null ? "Not set" : data?.media_recording1_name}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="field-wrapper">
                        <div className="label">Video</div>
                        <div className="value">{" "}
                          {data?.media_recording1_url && (
                            <Embed options={{ maxwidth: 1000 }} url={data?.media_recording1_url} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-6">
                <div className="field-group">
                  <div className="field-group-header" style={{ marginTop: "0" }}>Recording 2</div>
                  <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-12 lg:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Composer</div>
                        <div className="value">{" "}
                          {data?.media_recording2_composer === null ? "Not set" : data?.media_recording2_composer}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Name of Piece</div>
                        <div className="value">{" "}
                          {data?.media_recording2_name === null ? "Not set" : data?.media_recording2_name}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="field-wrapper">
                        <div className="label">Video</div>
                        <div className="value">{" "}
                          {data?.media_recording2_url && (
                            <Embed options={{ maxwidth: 1000 }} url={data?.media_recording2_url} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        )}
        {tab === 7 && (
          <>
            <div id="references-tab" className="tab-content">
              <div id="referee-1" className="field-group">
                <div className="field-group-header" style={{ marginTop: "0" }}>Referee 1</div>
                <div className="grid grid-cols-12 gap-5">
                  <div className="col-span-12 md:col-span-6 lg:col-span-3">
                    <div className="field-wrapper">
                      <div className="label">Name</div>
                      <div className="value">
                        {data?.referee_1_name === null ? "Not Set" : data?.referee_1_name}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-3">
                    <div className="field-wrapper">
                      <div className="label">Position</div>
                      <div className="value">
                        {data?.referee_1_position === null ? "Not Set" : data?.referee_1_position}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-3">
                    <div className="field-wrapper">
                      <div className="label">Email Address</div>
                      <div className="value">
                        {data?.referee_1_email === null ? "" : data?.referee_1_email}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-3">
                    <div className="field-wrapper">
                      <div className="label">Phone Number</div>
                      <div className="value">
                        {data?.referee_1_phone === null ? "" : data?.referee_1_phone}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-wrapper">
                      <div className="label">Reference</div>
                      <div className="value">
                        {data?.referee_1_reference === null ? "" : nl2br(data?.referee_1_reference)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="referee-2" className="field-group">
                <div className="field-group-header">Referee 2</div>
                <div className="grid grid-cols-12 gap-5">
                  <div className="col-span-12 md:col-span-6 lg:col-span-3">
                    <div className="field-wrapper">
                      <div className="label">Name</div>
                      <div className="value">
                        {data?.referee_2_name === null ? "Not Set" : data?.referee_2_name}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-3">
                    <div className="field-wrapper">
                      <div className="label">Position</div>
                      <div className="value">
                        {data?.referee_2_position === null ? "Not Set" : data?.referee_2_position}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-3">
                    <div className="field-wrapper">
                      <div className="label">Email Address</div>
                      <div className="value">
                        {data?.referee_2_email === null ? "" : data?.referee_2_email}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-3">
                    <div className="field-wrapper">
                      <div className="label">Phone Number</div>
                      <div className="value">
                        {data?.referee_2_phone === null ? "" : data?.referee_2_phone}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-wrapper">
                      <div className="label">Reference</div>
                      <div className="value">
                        {data?.referee_2_reference === null ? "" : nl2br(data?.referee_2_reference)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}

export default Application;

const DataFields = ({ name, value }) => {
  return (
    <div className=" mt-0 mr-3 md:my-2">
      <p>{name} </p>
      <p className=" bg-light-grey py-2 px-5 ">
        {value === null ? "N/L" : value}
      </p>
    </div>
  );
};
