import React, { useCallback, useEffect, useState } from "react";
import Layout from "../layout";
import { BiSearchAlt2 } from "react-icons/bi";
import NewsList from "./news";
import Pagination from "react-js-pagination";
// import { useQuery } from "react-query";
import api from "../../service/axios";
import Loading from "../loading";
import OfflineScreen from "../offline";

function News() {
  const [totalRecord, setTotalRecord] = useState();
  const [active, setActive] = useState(1);
  const [perPage] = useState(10);
  const [search, setSearch] = useState("");
  // const [searchNews, setSearchNews] = useState([]);
  const [newses, setNewses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageFound, setPageFound] = useState(false);
  const getInitialData = useCallback(
    async (number) => {
      //http request
      try {
        const user_id = localStorage.getItem("id");
        console.log({user_id:user_id});
        const { data, headers } = await api.get(
          `/trusteenews?user=${user_id}&per_page=${perPage}&page=${number}`
        );

        //get data from local storage
        setNewses(data);
        setTotalRecord(headers["x-wp-total"]);

        //set data to the local storage
        localStorage.setItem("news", JSON.stringify(data));
        localStorage.setItem("totalNews", parseInt(headers["x-wp-total"]));
        setLoading(false);
      } catch (error) {
        setPageFound(true);
        setLoading(false);
        //console.log("Page not found...");
      }
    },
    [perPage]
  );

  // const indexOfLastTodo = active * perPage;
  // const indexOfFirstTodo = indexOfLastTodo - perPage;

  const handlePageChange = async (pageNumber) => {
    setActive(pageNumber);
    await getInitialData(pageNumber);
  };
  const newsSearch = useCallback(async (perPage) => {
    setLoading(true);
    try {
      if (search) {
        const user_id = localStorage.getItem("id");
        const { data, headers } = await api.get(
          `/trusteenews?user=${user_id}&search=${search}`
        );
        console.log("search", { data });
        setActive(1);
        setNewses(data);
        setTotalRecord(headers["x-wp-total"]);
        setLoading(false);
      } else {
        const user_id = localStorage.getItem("id");
        const { data, headers } = await api.get(
          `/trusteenews?user=${user_id}`
        );
        setActive(1);
        setNewses(data);
        setTotalRecord(headers["x-wp-total"]);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error news", { error });
      setPageFound(true);
      setLoading(false);
    }
  }, [search]);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      await getInitialData(active);
    }
    if (navigator.onLine) {
      fetchData();
    } else {
      if (localStorage.getItem("news") === null) {
        setPageFound(true);
        setLoading(false);
        return;
      }
      setNewses(JSON.parse(localStorage.getItem("news")));
      setTotalRecord(localStorage.getItem("totalNews"));
      setLoading(false);
    }
  }, [getInitialData, active]);

  if (pageFound) {
    return <OfflineScreen />;
  }
  var loadedClass = (loading) ? '' : 'loaded';
  return (
    <Layout>
      {loading && <Loading />}
      <div id="content"
      className={`${loadedClass}`}
      >
        <div className="flex justify-between flex-wrap">
          <h1 className="font-bold text-2xl md:text-4xl">Trustee News</h1>
          <div className="flex">
            <input
              className="border p-3 bg-light-grey w-full"
              type="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search news here..."
            />
            <button
              onClick={newsSearch}
              className="button br0 "
            >
              <BiSearchAlt2 size={22} />
            </button>
          </div>
        </div>

        <div>
          <div className="flex justify-between bg-light-grey mt-3 p-2 items-center mb-5">
            <p>
              Page {active} /{" "}
              {totalRecord ? Math.ceil(Number(totalRecord) / perPage) : 0}
            </p>

            <Pagination
              activePage={active}
              itemsCountPerPage={perPage}
              totalItemsCount={Number(totalRecord)}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClassFirst = 'pagination-item first'
              itemClassLast = 'pagination-item last'
              itemClassPrev = 'pagination-item prev'
              itemClassNext = 'pagination-item next'
              firstPageText = '<<'
              lastPageText = '>>'
              nextPageText = 'Next >'
              prevPageText = '< Prev'
            />
          </div>
          {/* {search ? (
              searchNews.length ? (
                searchNews.map((n) => (
                  <NewsList
                    key={n.ID}
                    title={n.title}
                    excerpt={n.excerpt}
                    img={n.img}
                    date={n.date}
                    id={n.ID}
                  />
                ))
              ) : (
                <div className="text-center font-bold text-2xl">
                  Record not found
                </div>
              )
            ) : (
              newses.map((n) => (
                <NewsList
                  key={n.ID}
                  title={n.title}
                  excerpt={n.excerpt}
                  img={n.img}
                  date={n.date}
                  id={n.ID}
                />
              ))
            )} */}
          {newses ? (
            newses.length ? (
              newses.map((n) => (
                <NewsList
                  key={n.ID}
                  title={n.title}
                  excerpt={n.excerpt}
                  img={n.img}
                  date={n.date}
                  id={n.ID}
                />
              ))
            ) : (
              <h1> Record not found </h1>
            )
          ) : (
            <Loading />
          )}
        </div>

        <div className="flex justify-between bg-light-grey mt-3 p-2 items-center">
          <p>
            Page {active} /{" "}
            {totalRecord ? Math.ceil(Number(totalRecord) / perPage) : 0}
          </p>
          {newses && (
            <Pagination
              activePage={active}
              itemsCountPerPage={perPage}
              totalItemsCount={Number(totalRecord)}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClassFirst = 'pagination-item first'
              itemClassLast = 'pagination-item last'
              itemClassPrev = 'pagination-item prev'
              itemClassNext = 'pagination-item next'
              firstPageText = '<<'
              lastPageText = '>>'
              nextPageText = 'Next >'
              prevPageText = '< Prev'
            />
          )}
        </div>
      </div>
    </Layout>
  );
}

export default News;
