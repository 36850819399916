import React, { useState, useRef, useEffect, useCallback } from "react";
import Layout from "../layout";
import { useParams } from "react-router-dom";
import Embed from 'react-tiny-oembed'
import ReactToPrint from "react-to-print";
import Loading from "../loading";
import { AiFillPrinter } from "react-icons/ai";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import api from "../../service/axios";
// import Loading from "../loading";
import Youtube from "./youtube";
import { ComponentToPrint } from "./componentToPrint";
import OfflineScreen from "../offline";
const nl2br = require('react-nl2br');

function Ipl() {
  const { id } = useParams();
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [print, setPrint] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState();
  const [calls, setCalls] = useState(1); // Testing how many times this gets called
  let {result} = useState();

  const getCurrentIpl = useCallback(async () => {
    setCalls(calls + 1);
    console.log('calls: ' + calls);
    try {
      const user_id = localStorage.getItem("id");
      const { data } = await api.get(`/ipl/${id}?user=${user_id}`);
      localStorage.setItem("ipl-" + id, JSON.stringify(data));
      console.log({ data });
      return { result: data };
    } catch (error) {
      setError(true);
    }
  }, [id]);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const { result } = await getCurrentIpl();
      setData(result);
      setLoading(false);
    }
    if (navigator.onLine) {
    fetchData();
  } else if (localStorage.getItem("ipl-" + id) !== null) {
      console.log('is offline');
      setData(JSON.parse(localStorage.getItem("ipl-" + id)));
      console.log(JSON.parse(localStorage.getItem("ipl-" + id)));
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  }, [id, getCurrentIpl]);

  const [tab, setTab] = useState(1);
  if (error) {
    return <OfflineScreen />;
  }
  var loadedClass = (loading) ? '' : 'loaded';
  return (
    <Layout>
      {loading && <Loading />}
      <div id="content"
      className={`${loadedClass}`}
      >
        <div className="flex">
          <div className="flex-grow">
            <div className="page-sub-header">IPL Application</div>
            <h1 className="text-2xl  md:text-4xl font-bold ">
              <span className="uppercase">{data?.last_name}</span> {data?.first_name}
            </h1>
          </div>
          <div className="shrink">
            <Link to="/ipl">
              <p className="button br0" >
                <IoIosArrowBack size={24} />
                <span className="ml2 hidden md:inline-block"> Back</span>
              </p>
            </Link>
            {print && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setPrint(false)}>
                    &times;
                  </span>
                  <div>
                    <ReactToPrint
                      trigger={() => (
                        <button
                          className="button br0"
                          onClick={() => console.log({ trigger: "false" })}
                        >
                          Print this out!
                        </button>
                      )}
                      content={() => componentRef.current}
                    />
                    <ComponentToPrint ref={componentRef} data={data} />
                  </div>
                </div>
              </div>
            )}
            <button
              onClick={() => setPrint(true)}
              className="button br0 hidden md:inline-flex ml-5"
            ><AiFillPrinter size={24} /></button>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-2 mt-5">
          <div className="col-span-6 md:col-span-2">
            <div className="field-wrapper">
              <div className="label">A Number</div>
              <div className="value">{data?.a_number === null ? "" : data?.a_number}</div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="field-wrapper">
              <div className="label">Instrument</div>
              <div className="value">{data?.instrument_friendly === null ? "" : data?.instrument_friendly}</div>
            </div>
          </div>
          <div className="col-span-6 md:col-span-2">
            <div className="field-wrapper">
              <div className="label">Age</div>
              <div className="value">{data?.age === null ? "" : data?.age}</div>
            </div>
          </div>
          <div className="col-span-6 md:col-span-2">
            <div className="field-wrapper">
              <div className="label">Year</div>
              <div className="value">{data?.year === null ? "" : data?.year}</div>
            </div>
          </div>
          <div className="col-span-6 md:col-span-2">
            <div className="field-wrapper">
              <div className="label">Required</div>
              <div className="value">
                {data?.required_1 === null ? "" : new Intl.NumberFormat("en-GB", {
                  style: "currency",
                  currency: "GBP",
                  maximumFractionDigits: 0
                }).format(data?.required_1)}
              </div>
            </div>
          </div>
        </div>
        <div className="md:hidden tab-selector-wrapper">
          <select name="" id="" onChange={event => setTab(event.target.options.selectedIndex + 1)}>
            <option value="1">Personal</option>
            <option value="2">Course</option>
            <option value="3">Education</option>
            <option value="4">Focus</option>
            <option value="5">Finance</option>
            <option value="6">Media</option>
            <option value="7">References</option>
          </select>
        </div>
        <div className="overflow-x-scroll md:overflow-hidden mt-5 tabs-wrapper hidden md:flex">
          <div
            onClick={() => setTab(1)}
            className={
              tab === 1
                ? "tab active"
                : "tab"
            }
          >
            Personal
          </div>
          <div
            onClick={() => setTab(2)}
            className={
              tab === 2
                ? "tab active"
                : "tab"
            }
          >
            Purchase
          </div>
          <div
            onClick={() => setTab(3)}
            className={
              tab === 3
                ? "tab active"
                : "tab"
            }
          >
            Education
          </div>
          <div
            onClick={() => setTab(4)}
            className={
              tab === 4
                ? "tab active"
                : "tab"
            }
          >
            Focus
          </div>
          <div
            onClick={() => setTab(5)}
            className={
              tab === 5
                ? "tab active"
                : "tab"
            }
          >
            Finance
          </div>
          <div
          onClick={() => setTab(6)}
          className={
            tab === 6
            ? "tab active"
            : "tab"
          }
          >
          Media
          </div>
          <div
            onClick={() => setTab(7)}
            className={
              tab === 7
                ? "tab active"
                : "tab"
            }
          >
            References
            </div>

        </div>
        {tab === 1 && (
          <>
            <div id="personal-tab" className="tab-content">
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 md:col-span-4">
                <div className="field-wrapper">
                  <div className="label">Nationality</div>
                  <div className="value">
                    {data?.nationality === null ? "" : data?.nationality}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-2">
                <div className="field-wrapper">
                  <div className="label">Date of birth</div>
                  <div className="value">
                    {data?.dob === null ? "" : data?.dob}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Previous Surname</div>
                  <div className="value">
                    {data?.previous_surname === null ? "" : data?.previous_surname}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-4">
                <div className="field-wrapper">
                  <div className="label">Phone</div>
                  <div className="value">
                    {data?.phone === null ? "" : data?.phone}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-8">
                <div className="field-wrapper">
                  <div className="label">Email</div>
                  <div className="value">
                    {data?.email === null ? "" : data?.email}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Permanent Address</div>
                  <div className="value">
                    {data?.address_permanent === null ? "" : nl2br(data?.address_permanent)}
                    <br/>
                    {data?.address_permanent_city === null ? "" : data?.address_permanent_city}
                    <br/>
                    {data?.address_permanent_postcode === null ? "" : data?.address_permanent_postcode}
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Term Time Address</div>
                  <div className="value">
                    {data?.address_term === null ? "" : nl2br(data?.address_term)}
                    <br/>
                    {data?.address_term_city === null ? "" : data?.address_term_city}
                    <br/>
                    {data?.address_term_postcode === null ? "" : data?.address_term_postcode}
                  </div>
                </div>
              </div>
            </div>
            </div>
          </>
        )}
        {tab === 2 && (
          <>
          <div id="purchase-tab" className="tab-content">
            <div id="inst-1" className="field-group">
              <div className="grid grid-cols-12 gap-5">
                <div className="col-span-12 md:col-span-4">
                  <div className="field-wrapper">
                    <div className="label">Make of instrument</div>
                    <div className="value">
                      {data?.instrument_make === null ? "" : data?.instrument_make}
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-4">
                  <div className="field-wrapper">
                    <div className="label">When was it made?</div>
                    <div className="value">
                      {data?.instrument_made === null ? "" : data?.instrument_made}
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-4">
                  <div className="field-wrapper">
                    <div className="label">Asking Price</div>
                    <div className="value">
                      {data?.instrument_price === null ? "" : new Intl.NumberFormat("en-GB", {
                        style: "currency",
                        currency: "GBP",
                        maximumFractionDigits: 0
                      }).format(data?.instrument_price)}
                    </div>
                  </div>
                </div>
                <div className="col-span-12">
                  <div className="field-wrapper">
                    <div className="label">Why do you particularly favour it?</div>
                    <div className="value">
                      {data?.instrument_why === null ? "" : data?.instrument_why}
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-8">
                  <div className="field-wrapper">
                    <div className="label">From whom have you had an independent valuation?</div>
                    <div className="value">
                      {data?.instrument_valuer === null ? "" : data?.instrument_valuer}
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-4">
                  <div className="field-wrapper">
                    <div className="label">Sale of current instrument</div>
                    <div className="value">
                      {data?.instrument_current_price === null ? "" : new Intl.NumberFormat("en-GB", {
                        style: "currency",
                        currency: "GBP",
                        maximumFractionDigits: 0
                      }).format(data?.instrument_current_price)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </>
        )}
        {tab === 3 && (
          <>
            <div className="tab-content">
              <div className="field-group">
                <div className="field-group-header" style={{ marginTop: "0" }}>Postgraduate</div>
                <div className="grid grid-cols-12 gap-5">
                  <div className="col-span-12 lg:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Institution Name</div>
                      <div className="value">
                        {data?.education_postgrad_name_visible === null ? "" : data?.education_postgrad_name_visible}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-4">
                    <div className="field-wrapper">
                      <div className="label">Course Title</div>
                      <div className="value">
                        {data?.education_postgrad_course_title === null ? "" : data?.education_postgrad_course_title}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-2">
                    <div className="field-wrapper">
                      <div className="label">Graduation Date</div>
                      <div className="value">
                        {data?.education_postgrad_graduation_date === null ? "" : data?.education_postgrad_graduation_date}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="field-group">
                <div className="field-group-header">Undergraduate / Other Degree(s)</div>
                <div className="grid grid-cols-12 gap-5">
                  <div className="col-span-12">
                    <div className="field-group-sub-header">Undergraduate</div>
                  </div>
                  <div className="col-span-12 lg:col-span-4">
                    <div className="field-wrapper">
                      <div className="label">Institution Name</div>
                      <div className="value">
                        {data?.education_undergrad_name_visible === null ? "" : data?.education_undergrad_name_visible}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-4">
                    <div className="field-wrapper">
                      <div className="label">Course Title</div>
                      <div className="value">
                        {data?.education_undergrad_course_title === null ? "" : data?.education_undergrad_course_title}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-3 lg:col-span-2">
                    <div className="field-wrapper">
                      <div className="label">Graduation Date</div>
                      <div className="value">
                        {data?.education_undergrad_graduation_date === null ? "" : data?.education_undergrad_graduation_date}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-3 lg:col-span-2">
                    <div className="field-wrapper">
                      <div className="label">Class</div>
                      <div className="value">
                        {data?.education_undergrad_class === null ? "" : data?.education_undergrad_class}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-group-sub-header">Other</div>
                  </div>
                  <div className="col-span-12 lg:col-span-4">
                    <div className="field-wrapper">
                      <div className="label">Institution Name</div>
                      <div className="value">
                        {data?.education_other_name === null ? "" : data?.education_other_name}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-4">
                    <div className="field-wrapper">
                      <div className="label">Course Title</div>
                      <div className="value">
                        {data?.education_other_course_title === null ? "" : data?.education_other_course_title}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-3 lg:col-span-2">
                    <div className="field-wrapper">
                      <div className="label">Graduation Date</div>
                      <div className="value">
                        {data?.education_other_graduation_date === null ? "" : data?.education_other_graduation_date}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-3 lg:col-span-2">
                    <div className="field-wrapper">
                      <div className="label">Class</div>
                      <div className="value">
                        {data?.education_other_class === null ? "" : data?.education_other_class}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {tab === 4 && (
          <div className="tab-content">
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 lg:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Main Focus of Studies</div>
                  <div className="value textarea">
                  {data?.education_focus === null ? "Not set" : nl2br(data?.education_focus)}
                  </div>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Most Significant Performances</div>
                  <div className="value textarea">
                  {data?.performances === null ? "Not set" : nl2br(data?.performances)}
                  </div>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-6">
                <div className="field-wrapper">
                  <div className="label">Current Situation / Career Development</div>
                  <div className="value textarea">
                  {data?.current_situation === null ? "Not set" : nl2br(data?.current_situation)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {tab === 5 && (
          <>
            <div id="" className="tab-content">
            <div className="field-group">
              <div className="field-group-header" style={{ marginTop: "0" }}>Income</div>
              <div className="grid grid-cols-12 gap-5">
                <div className="col-span-12 md:col-span-6">
                  <div className="field-group-sub-header">Year 1</div>
                  <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Recital / Orchestral work</div>
                        <div className="value">
                          {data?.income_1_musical === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.income_1_musical)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Non-musical employment</div>
                        <div className="value">
                          {data?.income_1_nonmusical === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.income_1_nonmusical)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Teaching</div>
                        <div className="value">
                          {data?.income_1_teaching === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.income_1_teaching)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Other sources</div>
                        <div className="value">
                          {data?.income_1_other === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.income_1_other)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="field-wrapper is-total">
                        <div className="label">TOTAL</div>
                        <div className="value">
                          {data?.income_1_total === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.income_1_total)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-6">
                  <div className="field-group-sub-header">Year 2</div>
                  <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Recital / Orchestral work</div>
                        <div className="value">
                          {data?.income_2_musical === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.income_2_musical)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Non-musical employment</div>
                        <div className="value">
                          {data?.income_2_nonmusical === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.income_2_nonmusical)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Teaching</div>
                        <div className="value">
                          {data?.income_2_teaching === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.income_2_teaching)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Other sources</div>
                        <div className="value">
                          {data?.income_2_other === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.income_2_other)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="field-wrapper is-total">
                        <div className="label">TOTAL</div>
                        <div className="value">
                          {data?.income_2_total === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.income_2_total)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="field-group">
              <div className="field-group-header">Outgoings</div>
              <div className="grid grid-cols-12 gap-5">
                <div className="col-span-12 md:col-span-6">
                  <div className="field-group-sub-header">Year 1</div>
                  <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Rent / Mortgage</div>
                        <div className="value">
                          {data?.expenses_1_rent === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.expenses_1_rent)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Maintenance</div>
                        <div className="value">
                          {data?.expenses_1_maintenance === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.expenses_1_maintenance)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Travel</div>
                        <div className="value">
                          {data?.expenses_1_travel === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.expenses_1_travel)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Instrument Costs, insurance etc</div>
                        <div className="value">
                          {data?.expenses_1_costs === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.expenses_1_costs)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="field-wrapper is-total">
                        <div className="label">TOTAL</div>
                        <div className="value">
                          {data?.expenses_1_total === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.expenses_1_total)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-6">
                  <div className="field-group-sub-header">Year 2</div>
                  <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Rent / Mortgage</div>
                        <div className="value">
                          {data?.expenses_2_rent === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.expenses_2_rent)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Maintenance</div>
                        <div className="value">
                          {data?.expenses_2_maintenance === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.expenses_2_maintenance)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Travel</div>
                        <div className="value">
                          {data?.expenses_2_travel === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.expenses_2_travel)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 md:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Instrument Costs, insurance etc</div>
                        <div className="value">
                          {data?.expenses_2_costs === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.expenses_2_costs)}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="field-wrapper is-total">
                        <div className="label">TOTAL</div>
                        <div className="value">
                          {data?.expenses_2_total === null ? "" : new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0
                          }).format(data?.expenses_2_total)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="field-group">
              <div className="field-group-header">Debt / Funding</div>
                <div className="grid grid-cols-12 gap-5">
                  <div className="col-span-12 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Student Debt</div>
                      <div className="value">
                        {data?.financial_debt_student === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(data?.financial_debt_student)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Other Debt</div>
                      <div className="value">
                        {data?.financial_debt_other === null ? "" : new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(data?.financial_debt_other)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-wrapper">
                      <div className="label">Repayment Terms</div>
                      <div className="value">
                        {data?.financial_debt_terms === null ? "" : data?.financial_debt_terms}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Future Funding</div>
                      <div className="value textarea">
                        {data?.financial_funding_applied_name === null || data?.financial_funding_applied_name == '' ? "" : data?.financial_funding_applied_name+" - " + new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(data?.financial_funding_applied_amount)}<br />
                        {data?.financial_funding_applied2_name === null || data?.financial_funding_applied2_name == '' ? "" : data?.financial_funding_applied2_name+" - " + new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(data?.financial_funding_applied2_amount)}<br />
                        {data?.financial_funding_applied3_name === null || data?.financial_funding_applied3_name == '' ? "" : data?.financial_funding_applied3_name+" - " + new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(data?.financial_funding_applied3_amount)}<br />
                        {data?.financial_funding_applied4_name === null || data?.financial_funding_applied4_name == '' ? "" : data?.financial_funding_applied4_name+" - " + new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(data?.financial_funding_applied5_amount)}<br />
                        {data?.financial_funding_applied4_name === null || data?.financial_funding_applied5_name == '' ? "" : data?.financial_funding_applied5_name+" - " + new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(data?.financial_funding_applied5_amount)}<br />
                        {data?.financial_funding_applied6_name === null || data?.financial_funding_applied6_name == '' ? "" : data?.financial_funding_applied6_name+" - " + new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 0
                        }).format(data?.financial_funding_applied6_amount)}<br />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <div className="field-wrapper">
                      <div className="label">Other information relevant to application</div>
                      <div className="value textarea">
                        {data?.other_relevant_info === null ? "" : nl2br(data?.other_relevant_info)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {tab === 6 && (
          <div className="tab-content">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 lg:col-span-6">
                <div className="field-group">
                  <div className="field-group-header" style={{ marginTop: "0" }}>Recording 1</div>
                  <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-12 lg:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Composer</div>
                        <div className="value">{" "}
                          {data?.media_recording1_composer === null ? "Not set" : data?.media_recording1_composer}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Name of Piece</div>
                        <div className="value">{" "}
                          {data?.media_recording1_name === null ? "Not set" : data?.media_recording1_name}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="field-wrapper">
                        <div className="label">Video</div>
                        <div className="value">{" "}
                          {data?.media_recording1_url && (
                            <Embed options={{ maxwidth: 1000 }} url={data?.media_recording1_url} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-6">
                <div className="field-group">
                  <div className="field-group-header" style={{ marginTop: "0" }}>Recording 2</div>
                  <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-12 lg:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Composer</div>
                        <div className="value">{" "}
                          {data?.media_recording2_composer === null ? "Not set" : data?.media_recording2_composer}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <div className="field-wrapper">
                        <div className="label">Name of Piece</div>
                        <div className="value">{" "}
                          {data?.media_recording2_name === null ? "Not set" : data?.media_recording2_name}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="field-wrapper">
                        <div className="label">Video</div>
                        <div className="value">{" "}
                          {data?.media_recording2_url && (
                            <Embed options={{ maxwidth: 1000 }} url={data?.media_recording2_url} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        )}
        {tab === 7 && (
          <>
            <div id="references-tab" className="tab-content">
              <div id="referee-1" className="field-group">
                <div className="field-group-header" style={{ marginTop: "0" }}>Referee 1</div>
                <div className="grid grid-cols-12 gap-5">
                  <div className="col-span-12 md:col-span-6 lg:col-span-3">
                    <div className="field-wrapper">
                      <div className="label">Name</div>
                      <div className="value">
                        {data?.referee_1_name === null ? "Not Set" : data?.referee_1_name}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-3">
                    <div className="field-wrapper">
                      <div className="label">Position</div>
                      <div className="value">
                        {data?.referee_1_position === null ? "Not Set" : data?.referee_1_position}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-3">
                    <div className="field-wrapper">
                      <div className="label">Email Address</div>
                      <div className="value">
                        {data?.referee_1_email === null ? "" : data?.referee_1_email}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-3">
                    <div className="field-wrapper">
                      <div className="label">Phone Number</div>
                      <div className="value">
                        {data?.referee_1_phone === null ? "" : data?.referee_1_phone}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-wrapper">
                      <div className="label">Reference</div>
                      <div className="value">
                        {data?.referee_1_reference === null ? "" : nl2br(data?.referee_1_reference)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="referee-2" className="field-group">
                <div className="field-group-header">Referee 2</div>
                <div className="grid grid-cols-12 gap-5">
                  <div className="col-span-12 md:col-span-6 lg:col-span-3">
                    <div className="field-wrapper">
                      <div className="label">Name</div>
                      <div className="value">
                        {data?.referee_2_name === null ? "Not Set" : data?.referee_2_name}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-3">
                    <div className="field-wrapper">
                      <div className="label">Position</div>
                      <div className="value">
                        {data?.referee_2_position === null ? "Not Set" : data?.referee_2_position}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-3">
                    <div className="field-wrapper">
                      <div className="label">Email Address</div>
                      <div className="value">
                        {data?.referee_2_email === null ? "" : data?.referee_2_email}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-3">
                    <div className="field-wrapper">
                      <div className="label">Phone Number</div>
                      <div className="value">
                        {data?.referee_2_phone === null ? "" : data?.referee_2_phone}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="field-wrapper">
                      <div className="label">Reference</div>
                      <div className="value">
                        {data?.referee_2_reference === null ? "" : nl2br(data?.referee_2_reference)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}

export default Ipl;

const DataFields = ({ name, value }) => {
  return (
    <div className=" mt-0 mr-3 md:my-2">
      <p>{name} </p>
      <p className=" bg-light-grey py-2 px-5 ">
        {value === null ? "N/L" : value}
      </p>
    </div>
  );
};
