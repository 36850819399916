import React, { useCallback, useEffect, useState,useContext } from "react";
import Layout from "../layout";
import { AiFillCloseCircle } from "react-icons/ai";
import News from "./news";
import { Link } from "react-router-dom";
import api from "../../service/axios";
import { useQuery } from "react-query";
import Loading from "../loading";
//import Version from "../../version";
import { AuthContext } from "../../context/auth";
import { LOGOUT } from "../../context/types";
function Home() {
  // Logout related
  const { dispatch } = useContext(AuthContext);
  // Tips
  const [tips, setTips] = React.useState([
    {
      id: "add-to-home",
      text: "Don't forget to 'Add to Home Screen' on your phone/tablet for the best user experience.",
      isHidden: (localStorage.getItem("hideTip-add-to-home") === null) ? false : true
    },
    {
      id: "news",
      text: "Don't forget to check the 'News' section regularly for updates from the CMMT office",
      isHidden: (localStorage.getItem("hideTip-news") === null) ? false : true
    },
  ]);
  console.log('tips');
  console.log(tips);
  const removeTip = index => {
    const newTips = [...tips];
    localStorage.setItem("hideTip-" + tips[index].id,true);
    newTips.splice(index, 1);
    setTips(newTips);
    console.log(tips);
  };
  function Tip({ tip, index, removeTip }) {
    return (
      <div id={`tip-${index}`}
        className="item tip"
      >
        <p className="">
        <b>TIP:</b> {tip.text}
        </p>
        <span href="#" className="close mt-2 mr-2 cursor-pointer"
        onClick={() => removeTip(index)}
        >
        <AiFillCloseCircle size={32} />
        </span>
      </div>
    );
  }
  // Other
  const [news, setNews] = useState([]);
  const [totalStudygrants, setTotalStudygrants] = useState(Number || 0);
  const [totalIpl, setTotalIpl] = useState(Number || 0);
  const user_id = localStorage.getItem("id");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getHomeData = useCallback(
    async (number) => {
      //http request
      try {
        const user_id = localStorage.getItem("id");
        //http request
        const { data, headers } = await api.get(
          `/user/home?user=${user_id}`
        );
        //console.log(data);
        localStorage.setItem("display_name", data.display_name);
        localStorage.setItem("first_name", data.first_name);
        localStorage.setItem("last_name", data.last_name);
        localStorage.setItem("nickname", data.nickname);
        localStorage.setItem("last_login",data.last_login);
        localStorage.setItem("last_login_friendly",data.last_login_friendly);
        localStorage.setItem("total_studygrants",data.total_studygrants);
        localStorage.setItem("total_ipl",data.total_ipl);
        localStorage.setItem("recent_news",JSON.stringify(data.recent_news));
        //setNews(JSON.parse(localStorage.getItem("recent_news")));
        setNews(data.recent_news);
        setTotalStudygrants(data.total_studygrants);
        setTotalIpl(data.total_ipl);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error.response.status == 401) { // If failed authentication
          dispatch({ type: LOGOUT });
        }
      }
    },
    [user_id]
  );
  /*const { data } = useQuery("news", () =>
    api
      .get(`/user/home?user=${user_id}`)
      .then(({ data, headers }) => ({ data, headers }))
  );
  console.log(data);*/
  /*const hideTip = async (tipNumber) => {
    console.log("hide tip ", tipNumber);
    localStorage.setItem("hideTip" + tipNumber,true);
    var thisTip = getElementById('tip-'+tipNumber).remove();
  };*/
  const showTip1 = (localStorage.getItem("hideTip1") === null) ? true : false;
  const showTip2 = (localStorage.getItem("hideTip2") === null) ? true : false;
  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      await getHomeData();
    }
    if (navigator.onLine) {
      fetchData();
    } else {
      if (localStorage.getItem("recent_news") === null) {
        return;
      } else {
        setNews(JSON.parse(localStorage.getItem("recent_news")));
      }
      if (localStorage.getItem("total_studygrants") === null) {
        return;
      } else {
        setTotalStudygrants(localStorage.getItem("total_studygrants"));
      }
      if (localStorage.getItem("total_ipl") === null) {
        return;
      } else {
        setTotalIpl(localStorage.getItem("total_ipl"));
      }
      setLoading(false);
    }
  }, [getHomeData]);

  var loadedClass = (loading) ? '' : 'loaded';
  return (
    <Layout>
      {loading && <Loading />}
      <div id="content"
      className={`${loadedClass}`}
      >
        <h1 className="">
          Welcome back {localStorage.getItem("display_name")}
        </h1>
        <p className="text-xs secondary">Last login: {localStorage.getItem("last_login_friendly")}</p>
        <p className="">
          You can use the main menu at the{" "}
          <span className="block"> bottom of this app to navigate.</span>
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 ">
        {tips.map((tip, index) => (
          !tip.isHidden
            ? <Tip
              key={index}
              index={index}
              tip={tip}
              removeTip={removeTip}
            />
            : null

        ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5 content-center">
          <div className="col-span-2 md:col-span-1 self-center">
            <Link to="/news"><h2 className="subtitle mb-0">Latest Trustees News </h2></Link>
          </div>
          <div className="col-span-2 md:col-span-1 md:text-right hidden  md:block">
          <Link to="/news" className="button">View all news</Link>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4">
          {news ? (
            news.map((n) => {
              return <News key={n.ID} data={n} />;
            })
          ) : (
            <Loading />
          )}
        </div>

        <h2 className="subtitle mt-10">Application Summary</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
          <Link to="/applications" className="item">
            <div>
              <h1 className=" text-2xl font-bold ">Grant Applications</h1>
              <p className="secondary text-7xl font-bold ">
              {totalStudygrants}
              </p>
            </div>
            <div className="button mt-5 md:mt-0 md:float-right">
              View Grant applications
            </div>
          </Link>
          <Link to="/ipl" className="item">
            <div>
              <h1 className=" text-2xl font-bold ">IPL Applications</h1>
              <p className="secondary text-7xl font-bold ">
              {totalIpl}
              </p>
            </div>
            <div className="button mt-5 md:mt-0 md:float-right">
              View IPL applications
            </div>
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export default Home;
