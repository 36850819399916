import React, { useEffect } from "react";
import YouTube from "react-youtube";
function Youtube({ videoURL }) {

  const [videoUrl, setVideoUrl] = React.useState();
  let videoCode;
  let url;
  if (videoUrl) {
    //videoCode = videoUrl.split("v=")[1].split("&")[0];
    url = videoUrl.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    videoCode = (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  }

  useEffect(() => {
    setVideoUrl(videoURL);
  }, [videoURL]);

  const checkElapsedTime = (e) => {
    // const duration = e.target.getDuration();
    // const currentTime = e.target.getCurrentTime();

  };

  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  // const handleExerciseComplete = () => console.log("Do something");

  return (

    <YouTube
    className="w-full"
      videoId={videoCode}
      containerClassName="video-wrapper"
      className="embed embed-youtube"
      onStateChange={(e) => checkElapsedTime(e)}
      opts={opts}
    />
  );
}

export default Youtube;
