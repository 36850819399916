import packageJson from '../package.json';


function Version() {
  const currentVersion = packageJson.version;
  console.log(packageJson);
  return (
    <span
      className="version-wrapper"
    >
      {packageJson.version}
    </span>
  );
}

export default Version;
