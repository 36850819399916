import { css } from "@emotion/react";
import PuffLoader from "react-spinners/PuffLoader";

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  border-color: "#304659";
`;

export default function Loading() {
  return (
    <div
    className='loading-wrapper'
    >
      <PuffLoader
        css={`transform:translate(-50%,-50%);position:absolute; top:calc(50% - 90px); left:50%;`}
        size={100}
        color={`#304659`}
        loading={true}
        speedMultiplier={1.5}
      />
    </div>
  );
}
