import React from "react";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { Link } from "react-router-dom";
function ApplicationTable({ data, sortByHeaderKey,orderby,orderdir,aNumber,disc,inst }) {
  return (
    <table id="applications-table" className="table-auto">
      <thead>
        <tr>
          <th>
            <div className="flex w-30 items-center">
              <span className="">Last Name</span>
            </div>
          </th>
          <th>
            <div className="flex w-30 items-center">
              <span className="">First Name</span>
            </div>
          </th>
          <th>
            <div className="flex w-30 items-center">
              <span className="">A Number</span>
            </div>
          </th>

          <th>
            <div className="flex w-30 items-center">
              <span className="">Discipline</span>
            </div>
          </th>
          <th>
            <div className="flex w-30 items-center">
              <span className="">Instrument</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((i) => {
          var rowClass = (i.views === null) ? 'unread' : '';
          return (
            <tr
            key={i.ID}
            className = {`table-row ${rowClass}`}>
              <td
              className="last-name ml-1"
              >
                <Link to={`applications/${i.ID}`}> {i.last_name}</Link>
              </td>
              <td>
                <Link to={`applications/${i.ID}`}>{i.first_name}</Link>
              </td>
              <td>
                <Link to={`applications/${i.ID}`}>{i.a_number}</Link>
              </td>
              <td>
                <Link to={`applications/${i.ID}`}>{i.discipline_friendly}</Link>
              </td>
              <td>
                <Link to={`applications/${i.ID}`}>{i.instrument_friendly}</Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default ApplicationTable;
