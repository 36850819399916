import React from "react";
import { Link } from "react-router-dom";
function NewsList({ img, date, title, excerpt, id }) {
  return (
    <div className="item news mt-5">
      <Link to={`/news/${id}`}>
        <div className="flex">
          <div className="flex-shrink-0">
            {img && (
              <img
                className="w-28 h-full object-cover mb-5 md:mb-0 md:h-full md:w-48"
                src={img}
                alt={img}
              />
            )}
          </div>
          <div className="item-padding">
            <h4 className="mb-1 item-title">
              {title && title}
            </h4>
            <div className="item-date">
            {date && date}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default NewsList;
