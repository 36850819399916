import React, { useState,  useEffect, useCallback } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import Layout from "../layout";
import Pagination from "react-js-pagination";
import IplTable from "./table";
import api from "../../service/axios";
import Loading from "../loading";
import { RiFilter3Fill } from "react-icons/ri";
import OfflineScreen from "../offline";
import useWindowDimensions from "../../hooks/useWindowDimension";

function Ipls() {
  const { height } = useWindowDimensions();
  const [filterOpen, setFilterOpen] = useState(false);
  const [discipline, setDiscipline] = useState([]);
  const [instrument, setInstrument] = useState([]);
  // const [filterIpls, setFilterIpls] = useState([]);
  const [aNumber, setANumber] = useLocalStorage("ipl-aNumber", "");
  const [view, setView] = useLocalStorage("ipl-view", "");
  const [disc, setDisc] = useLocalStorage("ipl-disc", "");
  const [inst, setInst] = useLocalStorage("ipl-inst", "");
  //console.log('Height: ' + height);
  //false asc and true desc
  const [headerKey, setHeaderKey] = useState({ header: "", sort: false });
  // const [recordFound, setRecordFound] = useState(false);
  const [ipls, setIpls] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [totalRecord, setTotalRecord] = useState();
  const [page, setPage] = useLocalStorage("ipl-page", 1);
  const [perPage, setPerPage] = useLocalStorage("ipl-perPage", 20); // Default 20
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [calls, setCalls] = useState(0); // Testing how many times this gets called
  const getInitialData = useCallback(
    async (page) => {
      console.log('calls: ' + calls);
      try {
        setCalls(calls + 1);
        const user_id = localStorage.getItem("id");
        //http request
        const { data, headers } = await api.get(
          `/ipl?user=${user_id}&search=${aNumber}&read=${view}&discipline=${disc}&instrument=${inst}&page=${page}`
        );

        //get data from local storage
        setIpls(data.items);
        setDisciplines(data.disciplines);
        setInstruments(data.instruments);
        setTotalRecord(headers["x-wp-total"]);

        //set data to the local storage
        localStorage.setItem("ipls", JSON.stringify(data));
        localStorage.setItem("totalApp", parseInt(headers["x-wp-total"]));
        setLoading(false);
        return { data };
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    },
    [perPage]
  );

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const { data } = await getInitialData(page);
    }

    if (navigator.onLine) {
      fetchData();
    } else {
      if (localStorage.getItem("ipls") === null) {
        setError(true);
        setLoading(false);
        return;
      } else {
        setIpls(JSON.parse(localStorage.getItem("ipls")).items);
        setDisciplines(JSON.parse(localStorage.getItem("ipls")).disciplines);
        setInstruments(JSON.parse(localStorage.getItem("ipls")).instruments);
        setTotalRecord(localStorage.getItem("totalApp"));
        setLoading(false);
        console.log('Loading complete');
      }
    }
  }, [getInitialData, page]);

  const handlePageChange = async (page) => {
    setPage(page);
    await getInitialData(page);
  };

  const FilterSearch = useCallback(async () => {
    setLoading(true);
    setPage(1);
    // let result;
    const user_id = localStorage.getItem("id");
    const { data, headers } = await api.get(
      `/ipl?user=${user_id}&search=${aNumber}&read=${view}&discipline=${disc}&instrument=${inst}&page=1`
    );
    setIpls(data.items);
    setDisciplines(data.disciplines);
    setInstruments(data.instruments);
    setTotalRecord(headers["x-wp-total"]);
    setLoading(false);
  }, [view, aNumber, disc, inst]);

  const ClearSearch = useCallback(async () => {
    setLoading(true);
    setANumber("");
    setDisc("");
    setInst("");
    setView("");
    setPage(1);
    // let result;
    const user_id = localStorage.getItem("id");
    const { data, headers } = await api.get(
      `/ipl?user=${user_id}&search=&read=&discipline=&instrument=`
    );
    setIpls(data.items);
    setDisciplines(data.disciplines);
    setInstruments(data.instruments);
    setTotalRecord(headers["x-wp-total"]);
    setLoading(false);
  }, [view, aNumber, disc, inst]);

  /*const sortByHeaderKey = async (sortedByField) => {
    console.log("key ", sortedByField);
    setOrderby(sortedByField);
    if (order === 'asc') {
      setOrder('desc');
    } else {
      setOrder('asc');
    }
    //setHeaderKey({ header: sortedByField, sort: !headerKey.sort });

    fetchSortedData(sortedByField);
  };*/

  /*const fetchSortedData = async (fieldName) => {
    setLoading(true);
    const user_id = localStorage.getItem("id");
    const { data } = await api.get(
      `/ipl?user=${user_id}&search=${aNumber}&read=${view}&discipline=${disc}&instrument=${inst}&order_by=${orderby}&order=${order}`
    );
    //console.log("sorted asc data",data)
    setIpls(data.items);
    setDisciplines(data.disciplines);
    setInstruments(data.instruments);
    setLoading(false);
  };*/
  if (error) {
    return <OfflineScreen />;
  }
  var loadedClass = (loading) ? '' : 'loaded';
  return (
    <Layout>
      {loading && <Loading />}
      <div id="content"
      className={`${loadedClass}`}
      >
        <div className="flex justify-between">
          <h1 className=" text-2xl md:text-4xl font-bold ">
            IPL Applications
          </h1>
          <div
            onClick={() => setFilterOpen(!filterOpen)}
            className="flex button py-2 px-4 text-footer_color cursor-pointer br0"
          >
            <RiFilter3Fill size={24} /> <span className="ml2 hidden md:inline-block"> Filter</span>
          </div>
        </div>
        {filterOpen && (
          <div id="filter" className="w-full bg-light-grey p-5 mt-8">
            <h3 className="mb-5">IPLs Filter</h3>
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 md:col-span-6 lg:col-span-3">
                <input
                value={aNumber}
                value = {aNumber}
                onChange={(e) => setANumber(e.target.value)}
                className="w-full bg-filter_input_color py-3 px-4 "
                placeholder="Enter name or A Number here..."
                />
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-3">
                <select
                  onChange={(e) => setDisc(e.target.value)}
                  value = {disc}
                  className="w-full bg-filter_input_color py-3 px-4 "
                >
                  <option key="0" value="">Select Discipline </option>
                  {disciplines && Object.keys(disciplines).map(function(key) {
                      return <option key={key} value={key}>{disciplines[key]}</option>
                    })
                  }
                </select>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-3">
                <select
                  onChange={(e) => setInst(e.target.value)}
                  value = {inst}
                  className="w-full bg-filter_input_color py-3 px-4 "
                >
                  <option value="">Select Instrument </option>
                  {instruments && Object.keys(instruments).map(function(key) {
                      return <option key={key} value={key}>{instruments[key]}</option>
                    })
                  }
                </select>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-3">
                <select
                  onChange={(e) => setView(e.target.value)}
                  value = {view}
                  className="w-full bg-filter_input_color py-3 px-4 "
                >
                  <option value="">Show read & unread</option>
                  <option key="read" value="read"> Read</option>
                  <option key="unread" value="unread"> Unread</option>
                </select>
              </div>
            </div>
            <div className="flex flex-wrap mt-5">
              <button
                onClick={FilterSearch}
                className=" button py-4 px-8 font-bold text-footer_color cursor-pointer"
              >
                Filter Now
              </button>
              <button
                onClick={ClearSearch}
                className="ml-5 button-small place-self-end"
              >
                Clear Filter
              </button>
            </div>
          </div>
        )}
        <div className="flex justify-between bg-light-grey mt-3 p-2 mt-5 items-center">
          <p>
            Page {page} of {" "}
            {totalRecord ? Math.ceil(Number(totalRecord) / perPage) : 0}
          </p>
          {ipls && (
            <Pagination
              activePage={page}
              itemsCountPerPage={perPage}
              totalItemsCount={Number(totalRecord)}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClassFirst = 'pagination-item first'
              itemClassLast = 'pagination-item last'
              itemClassPrev = 'pagination-item prev'
              itemClassNext = 'pagination-item next'
              firstPageText = '<<'
              lastPageText = '>>'
              nextPageText = 'Next >'
              prevPageText = '< Prev'
            />
          )}
        </div>
        <div className="overflow-x-scroll md:overflow-hidden">
          {ipls && (
            <IplTable
              data={ipls}
            />
          )}
          {ipls=='' && <h3 className="text-center mt-3 mb-3">No applications</h3>}
        </div>
        <div className="flex justify-between bg-light-grey mt-3 p-2 items-center">
          <p>
            Page {page} /{" "}
            {totalRecord ? Math.ceil(Number(totalRecord) / perPage) : 0}
          </p>
          {ipls && (
            <Pagination
              activePage={page}
              itemsCountPerPage={perPage}
              totalItemsCount={Number(totalRecord)}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClassFirst = 'pagination-item first'
              itemClassLast = 'pagination-item last'
              itemClassPrev = 'pagination-item prev'
              itemClassNext = 'pagination-item next'
              firstPageText = '<<'
              lastPageText = '>>'
              nextPageText = 'Next >'
              prevPageText = '< Prev'
            />
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Ipls;
